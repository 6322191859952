export const distributorTableColumn = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Distributor Name',
        accessor: 'name',
      },
      {
        Header: 'Website',
        accessor: 'website',
      },
      {
        id: 'buttonLink',
        Header: '',
      },
    ],
  },
];