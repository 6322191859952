import React from 'react';
import { ErrorMessage } from 'react-hook-form';

interface ComponentProps {
  errors: any;
  name: string;
}

export default class ShowErrorMessages extends React.Component<ComponentProps> {
  public render(): React.ReactNode {
    return (
      <ErrorMessage errors={this.props.errors} name={this.props.name}>
      {({ messages }) =>
        messages &&
        Object.entries(messages).map(([type, message]) => (
          <div className="invalid-feedback" key={type} >{message}</div>
        ))
      }
    </ErrorMessage>
    );
  }
}