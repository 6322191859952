import React from 'react';
import {
  Container, Row, Col,
  Card, CardHeader
} from 'shards-react';
import { RouteComponentProps } from 'react-router-dom';
import Noty from 'noty';
import PageTitle from '../../components/common/PageTitle';
import CompanyForm from "../../components/custom/CompanyForm";
import { authApiCall } from '../../services/AuthApiCallService';
import { CompanyFormData } from '../../models';
import { connect, ConnectedProps } from 'react-redux';
import { RouteConstants } from '../../constants/RouteConstants';

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type AddCompanyProps = RouteComponentProps & ConnectedProps<typeof connector>;

class AddCompany extends React.Component<AddCompanyProps> {

  componentDidMount() {
    // Redirect unauthenticated users
    let userType = this.props.userData.user.role.type;
    if (userType !== "superadmin") {
      this.props.history.push(RouteConstants.ADMIN);
    }
  }

  private addNewcompanyClick = (company: CompanyFormData) => {
    authApiCall.post('/companies', {
      ...company
    })
    .then(response => {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'success',
        text: 'New Company Added',
        killer: true,
      }).show();
      this.props.history.push('/companies');
    }).catch(error => {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: error.message,
        killer: true,
      }).show();
    })
  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Add Company" subtitle="Add New Company" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="12" className="mb-4">
            {/* Complete Form Example */}
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">New Company</h6>
              </CardHeader>
              <CompanyForm submitButtonTitle="Add New company" onButtonClick={this.addNewcompanyClick} />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default connector(AddCompany);