export const merchantTableColumn = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Merchant Name',
        accessor: 'name',
      },
      {
        Header: 'Business Category',
        accessor: 'business_category',
      },
      {
        id: 'buttonLink',
        Header: '',
      },
    ],
  },
];