export const sampleTableColumnMini = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '',
        id: 'checkbox',
        accessor: '',
      },
      {
        Header: '',
        id: 'image',
        accessor: 'image.url',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'No. of Scans',
        accessor: 'scans.length',
      },
      {
        id: 'buttonClick',
        Header: '',
      },
    ],
  },
];