import { Scan } from './Scan';
import { Image } from './Image';
import { Manufacturer } from './Manufacturer';
import { Distributor } from './Distributor';
import { Collection } from './Collection';
import { IncludeDateData } from './Include';

export interface Sample extends IncludeDateData {
  id?: number;
  name: string;
  price_per_item: number;
  intended_use: string;
  manufacturer: Manufacturer;
  quantity: number;
  width: number;
  height: number;
  size: number;
  shape: string;
  thickness: number;
  strength: string;
  weight: number;
  flexibility: boolean;
  transparency: boolean;
  colour: string;
  batch_number: string;
  manufacturing_year: number;
  sirim_label: string;
  eco_label_license: string;
  purchase_date: string;
  last_order_qty: number;
  distributor: Distributor;
  sample_created_at: string;
  collection: Collection;
  created_at?: string;
  updated_at?: string;
  image: Image;
  scans: Scan[];
}

export interface SpectraData {
  spectradata: Scan[];
}

export type SampleFormData = Omit<Sample,
'id'|'manufacturer'|'distributor'|'collection'|'image'|'scans'|'created_at'|'updated_at'>;

export const SampleFormState = {
  name: '',
  price_per_item: 0,
  intended_use: '',
  quantity: 0,
  width: 0,
  height: 0,
  size: 0,
  shape: '',
  thickness: 0,
  strength: '',
  weight: 0,
  flexibility: false,
  transparency: false,
  colour: '',
  batch_number: '',
  manufacturing_year: 0,
  sirim_label: '',
  eco_label_license: '',
  purchase_date: '',
  last_order_qty: 0,
  sample_created_at: '',
};