export const userTableColumn = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role.name',
      },
      {
        Header: 'Company',
        accessor: 'company.company_name',
      },
      {
        Header: 'Confirmed',
        accessor: 'confirmed',
      },
      {
        id: 'buttonLink',
        Header: '',
      },
    ],
  },
];