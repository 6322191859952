import React from 'react';
import {
  Container, Row, Col,
  Card, CardHeader
} from 'shards-react';
import { RouteComponentProps } from 'react-router-dom';
import Noty from 'noty';
import PageTitle from '../../components/common/PageTitle';
import CollectionForm from "../../components/custom/CollectionForm";
import { authApiCall } from '../../services/AuthApiCallService';
import { CollectionFormData } from '../../models';

export default class AddCollection extends React.Component<RouteComponentProps> {

  private addNewCollectionClick = (collection: CollectionFormData) => {
    authApiCall.post('/collections', {
      ...collection
    })
    .then(response => {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'success',
        text: 'New Collection Added',
        killer: true,
      }).show();
      this.props.history.push('/collections');
    }).catch(error => {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: error.message,
        killer: true,
      }).show();
    })
  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Add Collection" subtitle="Add New Collection" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="12" className="mb-4">
            {/* Complete Form Example */}
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">New Collection</h6>
              </CardHeader>
              <CollectionForm submitButtonTitle="Add New Collection" onButtonClick={this.addNewCollectionClick} />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}