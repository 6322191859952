import React, { Children } from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import Collections from "./views/Collections/Collections";
import AddCollection from "./views/Collections/AddCollection";
import ViewCollection from "./views/Collections/ViewCollection";
import Samples from "./views/Samples/Samples";
import ViewSample from "./views/Samples/ViewSample";
import EditSample from "./views/Samples/EditSample";
import Scans from "./views/Scans/Scans";
import ViewScan from "./views/Scans/ViewScan";
import Manufacturers from "./views/Manufacturers/Manufacturers";
import AddManufacturer from "./views/Manufacturers/AddManufacturer";
import ViewManufacturer from "./views/Manufacturers/ViewManufacturer";
import Distributors from "./views/Distributors/Distributors";
import AddDistributor from "./views/Distributors/AddDistributor";
import ViewDistributor from "./views/Distributors/ViewDistributor";
import Companies from "./views/Companies/Companies";
import AddCompany from "./views/Companies/AddCompany";
import ViewCompany from "./views/Companies/ViewCompany";
import Merchants from "./views/Merchants/Merchants";
import AddMerchant from "./views/Merchants/AddMerchant";
import ViewMerchant from "./views/Merchants/ViewMerchant";
import Users from "./views/Users/Users";
import ViewUser from "./views/Users/ViewUser";
import AddUser from "./views/Users/AddUser";
import Spectrascans from './views/Spectrascans/Spectrascans';
import ViewSpectrascan from './views/Spectrascans/ViewSpectrascan';
import Calibrations from './views/Calibrations/Calibrations';
import ViewCalibration from './views/Calibrations/ViewCalibration';
import Dashboard from "./views/Dashboard";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

import { RouteConstants } from './constants/RouteConstants';

export default [
  {
    path: ["/", RouteConstants.LOGIN],
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to={`${RouteConstants.ADMIN}`} />,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.ADMIN,
    layout: DefaultLayout,
    component: Dashboard,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.COLLECTIONS,
    exact: true, // important
    layout: DefaultLayout,
    component: Collections,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.ADD_COLLECTION,
    exact: true, // important
    layout: DefaultLayout,
    component: AddCollection,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.GET_COLLECTION,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewCollection,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.SAMPLES,
    exact: true, // important
    layout: DefaultLayout,
    component: Samples,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.GET_SAMPLE,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewSample,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.EDIT_SAMPLE,
    exact: true, // important
    layout: DefaultLayout,
    component: EditSample,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.SCANS,
    exact: true, // important
    layout: DefaultLayout,
    component: Scans,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.GET_SCAN,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewScan,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.MANUFACTURERS,
    exact: true, // important
    layout: DefaultLayout,
    component: Manufacturers,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.ADD_MANUFACTURER,
    exact: true, // important
    layout: DefaultLayout,
    component: AddManufacturer,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.GET_MANUFACTURER,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewManufacturer,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.DISTRIBUTORS,
    exact: true, // important
    layout: DefaultLayout,
    component: Distributors,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.ADD_DISTRIBUTOR,
    exact: true, // important
    layout: DefaultLayout,
    component: AddDistributor,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.GET_DISTRIBUTOR,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewDistributor,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.COMPANIES,
    exact: true, // important
    layout: DefaultLayout,
    component: Companies,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.ADD_COMPANY,
    exact: true, // important
    layout: DefaultLayout,
    component: AddCompany,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.GET_COMPANY,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewCompany,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.MERCHANTS,
    exact: true, // important
    layout: DefaultLayout,
    component: Merchants,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.ADD_MERCHANT,
    exact: true, // important
    layout: DefaultLayout,
    component: AddMerchant,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.GET_MERCHANT,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewMerchant,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.USERS,
    exact: true, // important
    layout: DefaultLayout,
    component: Users,
    access: ['admin', 'superadmin']
  },
  {
    path: RouteConstants.ADD_USER,
    exact: true, // important
    layout: DefaultLayout,
    component: AddUser,
    access: ['admin', 'superadmin'],
    strict: false
  },
  {
    path: RouteConstants.GET_USER,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewUser,
    access: ['admin', 'superadmin']
  },
  {
    path: RouteConstants.SPECTRASCANS,
    exact: true, // important
    layout: DefaultLayout,
    component: Spectrascans,
    access: ['superadmin'],
  },
  {
    path: RouteConstants.GET_SPECTRASCAN,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewSpectrascan,
    access: ['superadmin'],
  },
  {
    path: RouteConstants.CALIBRATIONS,
    exact: true, // important
    layout: DefaultLayout,
    component: Calibrations,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: RouteConstants.GET_CALIBRATION,
    exact: true, // important
    layout: DefaultLayout,
    component: ViewCalibration,
    access: ['admin','authenticated','superadmin'],
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  }
];
