import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from 'shards-react';
import { RouteComponentProps } from 'react-router-dom';
import Noty from 'noty';
import MerchantForm from '../../components/custom/MerchantForm';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { Merchant, MerchantFormData } from '../../models';

export default class ViewMerchant extends React.Component<RouteComponentProps> {

  private merchant: Merchant = this.props.location.state.data;

  private updateMerchant = (merchant: MerchantFormData) => {
    authApiCall.put(`/merchants/${this.merchant.id}`, {
      ...merchant,
    })
    .then(response => {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'success',
        text: 'Merchant updated.',
        killer: true,
      }).show();
        //Replace the location state of the current route
        this.props.history.replace(this.props.location.pathname, { data: { ...response.data } });

        //2 Options:
        //1) Manually refresh the browser
        window.location.reload();
        //2) redirect back to main list
        //this.props.history.goBack();
    }).catch(error => {
      const err = error.response.data;
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: err.message,
        killer: true,
      }).show();
    });
  }

  private deleteMerchant = (id: any) => {
    let deletion = new Noty({
      theme: 'sunset',
      layout: 'topRight',
      text: 'Do you want to continue?',
      buttons: [
        Noty.button('YES', 'btn btn-success', () => {
          authApiCall.delete(`/merchants/${id}`)
            .then(response => {
              deletion.close();
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'warning',
                text: 'Merchant Deleted',
                killer: true,
              }).show();
              this.props.history.push('/merchants', {merchantDeleted: true});
            }).catch(error => {
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'error',
                text: error.message,
                killer: true,
              }).show();
              deletion.close();
            });
        }, { id: 'button1', 'data-status': 'ok' }),

        Noty.button('NO', 'btn btn-danger', () => {
          deletion.close();
        })
      ]
    });
    deletion.show();
  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={this.merchant.name}
            subtitle="Merchant Profile" md="12"
            className="ml-sm-auto mr-sm-auto"
          />
          <strong className="title-info">{`(ID:${this.merchant.id})`}</strong>
        </Row>
        <Row>
          <Col lg="4">
            <Card small className="mb-4 pt-3">
              <CardHeader className="border-bottom text-center">
                <div className="mb-3 mx-auto">
                  <img
                    className="rounded-circle"
                    src={`https://avatars.dicebear.com/v2/initials/${this.merchant.name}.svg`}
                    alt="Merchant Name"
                    width="110"
                  />
                </div>
                <h4 className="mb-0">{this.merchant.name}</h4>
                <span className="text-muted d-block mb-2">{this.merchant.ssm_registration}</span>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-4">
                  <strong className="text-muted d-block mb-2">
                    Joined At
                  </strong>
                  <span>{this.merchant.created_at}</span>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col lg="8" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="6">
                    <h6 className="m-0">Merchant Detail</h6>
                  </Col>
{/*                   <Col lg="6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.deleteMerchant(this.merchant.id)} theme="danger" className="mb-0">
                      Remove Merchant
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <MerchantForm
                  merchantInfo={this.merchant} 
                  submitButtonTitle="Update Merchant"
                  onButtonClick={this.updateMerchant}
                />
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    )
  }

}