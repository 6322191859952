import React, { Fragment } from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import routes from "./routes";
import { RouteConstants } from './constants/RouteConstants';
// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";
import Login from "./views/Auth/Login";
import withTracker from "./withTracker";
import { connect } from 'react-redux';

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "react-datetime/css/react-datetime.css";

//  Reference css in the app entry point 
import 'noty/lib/noty.css';
import 'noty/lib/themes/sunset.css';

import './assets/styles/style.css';

const mapState = (state) => (
  { userData: state.userData }
);

class App extends React.Component {

  constructor(props) {
    super(props);
    // console.log(this.props.userData);
  }

  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
        <Switch>
          {!Object.entries(this.props.userData).length > 0 ?
            <Fragment>
              <Redirect
                to={{
                  pathname: RouteConstants.LOGIN
                }}
              />
              <Route
                path={RouteConstants.LOGIN}
                component={Login}
                layout={LoginLayout}
              />
            </Fragment> : null
          }
          {routes.map((route, index) => {
            let userRoutes = [
              RouteConstants.USERS,
              RouteConstants.ADD_USER,
              RouteConstants.GET_USER
            ];
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker(props => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  })}
                />
              );  
          })
          }
        </Switch>
      </div>
    </Router>
    );
  }
}

export default connect(
  mapState
)(App);
