import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from 'shards-react';
import { RouteComponentProps } from 'react-router-dom';
import Noty from 'noty';
import CompanyForm from '../../components/custom/CompanyForm';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { Company, CompanyFormData } from '../../models';
import { time } from '../../services/convertTime';
import { connect, ConnectedProps } from 'react-redux';
import { RouteConstants } from '../../constants/RouteConstants';

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type ViewCompanyProps = RouteComponentProps & ConnectedProps<typeof connector>;

class ViewCompany extends React.Component<ViewCompanyProps> {

  private company: Company = this.props.location.state ? this.props.location.state.data : {};
  private companyFormData: CompanyFormData = this.props.location.state ? this.props.location.state.data : {};

  componentDidMount() {
    // Redirect unauthenticated users
    let userType = this.props.userData.user.role.type;
    if (userType !== "superadmin") {
      this.props.history.push(RouteConstants.ADMIN);
    }
  }

  private updateCompany = (company: CompanyFormData) => {
    authApiCall.put(`/companies/${this.company.id}`, {
      ...company
    })
      .then(response => {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'success',
          text: 'Company updated.',
          killer: true,
        }).show();

        //Replace the location state of the current route
        this.props.history.replace(this.props.location.pathname, { data: { ...response.data } });

        //2 Options:
        //1) Manually refresh the browser
        window.location.reload();
        //2) redirect back to main list
        //this.props.history.goBack();
      }).catch(error => {
        const err = error.response.data;
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'error',
          text: err.message,
          killer: true,
        }).show();
      });
  }

  private deleteCompany = (id: any) => {
    let deletion = new Noty({
      theme: 'sunset',
      layout: 'topRight',
      text: 'Do you want to continue?',
      buttons: [
        Noty.button('YES', 'btn btn-success', () => {
          authApiCall.delete(`/companies/${id}`)
            .then(response => {
              deletion.close();
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'warning',
                text: 'Company Deleted',
                killer: true,
              }).show();
              this.props.history.push('/companies', { companyDeleted: true });
            }).catch(error => {
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'error',
                text: error.message,
                killer: true,
              }).show();
              deletion.close();
            });
        }, { id: 'button1', 'data-status': 'ok' }),

        Noty.button('NO', 'btn btn-danger', () => {
          deletion.close();
        })
      ]
    });
    deletion.show();
  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={this.company.company_name}
            subtitle="Company Profile" md="12"
            className="ml-sm-auto mr-sm-auto"
          />
          <strong className="title-info">{`(ID:${this.company.id})`}</strong>
        </Row>
        <Row>
          <Col lg="4">
            <Card small className="mb-4 pt-3">
              <CardHeader className="border-bottom text-center">
                <div className="mb-3 mx-auto">
                  <img
                    className="rounded-circle"
                    src={`https://avatars.dicebear.com/v2/initials/${this.company.company_name}.svg`}
                    alt="Company Name"
                    width="110"
                  />
                </div>
                <h4 className="mb-0">{this.company.company_name}</h4>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-4">
                  <strong className="text-muted d-block mb-2">
                    Added At
                  </strong>
                  <span>{time(this.company.created_at)}</span>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col lg="8" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="6">
                    <h6 className="m-0">Company Detail</h6>
                  </Col>
{/*                   <Col lg="6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.deleteCompany(this.company.id)} theme="danger" className="mb-0">
                      Remove Company
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <CompanyForm
                  companyInfo={this.companyFormData}
                  submitButtonTitle="Update Company"
                  onButtonClick={this.updateCompany}
                />
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    )
  }

}

export default connector(ViewCompany);