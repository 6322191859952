import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  FormCheckbox,
  FormSelect,
  Button
} from "shards-react";
import { FormValidator, messages, regexes } from './FormValidator';
import ShowErrorMessages from './ShowErrorMesssages';
import Noty from 'noty';

interface State {
  email: string;
  username: string;
  password: string;
  userCredentialsInvalid: boolean,
}

type LoginFormState = Omit<State, 'userCredentialsInvalid'>;

interface ComponentProps {
  loginInfo?: any;
  submitButtonTitle: string;
  onButtonClick: (loginInfo: State) => void;
  onCheckUserCredentials: boolean;
}

class LoginForm extends React.Component<ComponentProps, State> {

  public state: State = {
    ...this.props.loginInfo
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if(this.state.userCredentialsInvalid !== this.props.onCheckUserCredentials) {
      this.setState({
        userCredentialsInvalid: this.props.onCheckUserCredentials,
      })
    }
  }

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value } as Pick<LoginFormState, keyof LoginFormState>);
  }

  private formSubmission = () => {
    const { email, username, password } = this.state;
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (username && password !== '') {
      if (true) {//email.match(regex)
        this.props.onButtonClick(this.state)
      } else {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'error',
          text: 'Please enter a valid email.',
          killer: true,
        }).show();
      }
    } else {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: 'Field cannot be empty.',
        killer: true,
      }).show();
    }
  }

  public render(): React.ReactNode {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <FormValidator>
                {(formVal: any) => (
                  <Form onSubmit={formVal.handleSubmit(this.formSubmission)}>
                    <Row form>
                      <Col md="12">
                        <label htmlFor="username">Username</label>
                        <input
                          className={`form-control ${formVal.errors.username ? "is-invalid" : ''}`}
                          id="username"
                          type="text"
                          name="username"
                          placeholder="Username"
                          defaultValue={this.state.username ? this.state.username : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.empty })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="username" />
                      </Col>
                      <Col md="12" className="form-group mt-4">
                        <label htmlFor="password">Password</label>
                        <input
                          className={`form-control ${formVal.errors.password ? "is-invalid" : ''}`}
                          id="password"
                          type="password"
                          name="password"
                          placeholder="Password"
                          defaultValue={this.state.password ? this.state.password : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.empty })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="password" />
                      </Col>
                      {this.state.userCredentialsInvalid && (
                        <Col md="12" className="text-center m-2 mb-3">
                          <div className="text-danger text-semibold">Invalid username or password.</div>
                        </Col>
                      )}
                    </Row>

                    <Row form className="mt-2 align-items-center justify-content-center">
                      <Button type="submit" size="lg" className="w-100">
                        {this.props.submitButtonTitle}
                      </Button>
                    </Row>
                  </Form>
                )}
              </FormValidator>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
  }
}

export default LoginForm;
