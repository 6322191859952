import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from 'shards-react';
import { Link, RouteComponentProps } from "react-router-dom";
import Noty from 'noty';
import CollectionForm from '../../components/custom/CollectionForm';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { Collection, CollectionFormData, Sample, Merchant } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';
import SlidingPanel from 'react-sliding-side-panel';
import ViewSamplePanel from '../Samples/ViewSamplePanel';
import { connect, ConnectedProps } from 'react-redux';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { InnerTable } from '../../components/tables/InnerTable';
import { sampleTableColumnMini } from '../../components/tables/tableColumn/SampleTableColumnMini';
import SpectralPlot from '../Scans/SpectralPlot';

interface State {
  isLoaded: boolean;
  selectedDataLabel: String;
  selectedData: number;
  openPanel: boolean;
  samples: Sample[];
  samplesCount: number;
  sample: any;
  selectedSamplesIds: any[];
  selectedSamples: any[];
  chartData: any;
  chartOptions: any;
  labelData: any;
  lineColorSet: boolean;
  test_spectrum_color: any;
  match_spectrum_color: any;
  lat: number;
  lng: number;
  zoom: number;
}
const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type ViewCollectionsProps = RouteComponentProps & ConnectedProps<typeof connector>;

class ViewCollection extends React.Component<ViewCollectionsProps, State> {

  constructor(props: any) {
    super(props);
    this.getNewPageItem = this.getNewPageItem.bind(this);
  }

  private collection: Collection = this.props.location.state.data;
  private merchant: Merchant = this.props.location.state.data.merchant;
  private spectralDataLabel = ['Spectral', 'Derivative', 'PLSDA', 'SVM', 'Random Forest'];

  public state: State = {
    isLoaded: false,
    selectedDataLabel: this.spectralDataLabel[0],
    selectedData: 0,
    openPanel: false,
    samples: [],
    samplesCount: 0,
    sample: {},
    selectedSamplesIds: [],
    selectedSamples: [],
    chartData: {},
    chartOptions: {},
    labelData: "",
    lineColorSet: false,
    test_spectrum_color: "",
    match_spectrum_color: "",
    lat: 3.0006,
    lng: 101.5335,
    zoom: 15,
  }

  componentDidMount() {
    console.log("collection data", this.collection);
    // console.log("merchant", this.merchant);
    // this.collection.samples.forEach((value, key) => {
    authApiCall.get(`/samples/countByCollectionID/${this.collection.id}`)
      .then(response => {
        // console.log(response.data.id, response.data);
        this.setState((state) => {
          return { samplesCount: response.data };
        });
      })
      .catch(error => {
        console.warn(error);
      })
    // });
  }

  private getNewPageItem(perPageLimit: number, startAt: number) {
    authApiCall.get(`/samples/findByCollectionID/${this.collection.id}?_limit=${perPageLimit}&_start=${startAt}`)
      .then(response => {
        console.log('Samples', response.data);
        this.setState({ samples: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  private updateCollection = (collection: CollectionFormData) => {
    authApiCall.put(`/collections/${this.collection.id}`, {
      ...collection,
      //samples: [1,2,3]
    })
      .then(response => {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'success',
          text: 'Collection updated.',
          killer: true,
        }).show();

        //Replace the location state of the current route
        this.props.history.replace(this.props.location.pathname, { data: { ...response.data } });

        //2 Options:
        //1) Manually refresh the browser
        window.location.reload();
        //2) redirect back to main list
        //this.props.history.goBack();
      }).catch(error => {
        console.warn(error);
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'error',
          text: error.message,
          killer: true,
        }).show();
      });
  }

  private deleteCollection = (id: any) => {
    let deletion = new Noty({
      theme: 'sunset',
      layout: 'topRight',
      text: 'Do you want to continue?',
      buttons: [
        Noty.button('YES', 'btn btn-success', () => {
          authApiCall.delete(`/collections/${id}`)
            .then(response => {
              deletion.close();
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'warning',
                text: 'Collection Deleted',
                killer: true,
              }).show();
              this.props.history.push('/collections', { collectionDeleted: true });
            }).catch(error => {
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'error',
                text: error.message,
                killer: true,
              }).show();
              deletion.close();
            });
        }, { id: 'button1', 'data-status': 'ok' }),

        Noty.button('NO', 'btn btn-danger', () => {
          deletion.close();
        })
      ]
    });
    deletion.show();
  }

  private setOpenPanel(openPanelState: boolean) {
    this.setState({
      openPanel: openPanelState
    });
  }

  private setSample(openPanelState: boolean, value: Sample) {
    this.setState({
      openPanel: openPanelState,
      sample: value
    });
  }

  private convertAnalysisDataLegacy(data: any) {
    if (data && data.length == undefined) {
      let outData = [];
      let keys = Object.keys(data);
      let numModels = data["ID Method"] ? Object.keys(data["ID Method"]).length : 0;
      for (let i = 0; i < numModels; i++) {
        let obj: any = {};
        for (let k = 0; k < keys.length; k++) {
          if (keys[k] == "Test Spectrum" || keys[k] == "Match Spectrum") {
            // x,y values were split into 2 arrays, combine into single array with nested x,y array values
            let spectrumData = data[keys[k]][i];
            if (spectrumData[0] && spectrumData[0].length > 0) {
              let slength = spectrumData[0].length;
              let tmpArray = [];
              for (let s = 0; s < slength; s++) {
                tmpArray.push([spectrumData[0][s], spectrumData[1][s]])
              }
              obj[keys[k]] = tmpArray;
            }
          } else {
            obj[keys[k]] = data[keys[k]][i];
          }
        }
        outData.push(obj);
      }
      data = outData;
    }
    return data;
  }

  private setSelectedSample(index: number, value: any) {
    // console.warn("value", value);
    let samples = this.state.selectedSamples;
    let ifSampleListed = samples.findIndex((obj) => {
      return obj.id == value.id;
    });
    if (ifSampleListed !== -1) {
      // console.log("samples", samples);
      // console.log("sample in list", ifSampleListed);
      samples = samples.filter((obj, index) => index !== ifSampleListed)
      this.setState({
        selectedSamples: samples,
      }, () => this.generateScansFromSamplesSelected());
    } else {
      let sample = {
        id: value.id,
        name: value.name,
        scans: value.scans,
      };
      this.setState((prevState, props) => {
        return {
          selectedSamples: [...prevState.selectedSamples, sample],
        };
      }, () => this.generateScansFromSamplesSelected());
    }
  }

  private setAllSamples(value: any[]) {
    //let samples = this.state.selectedSamples;
    let samples: any[] = [];
    if (this.state.selectedSamples.length <= 0) {
      // No selected samples
      value.forEach(item => {
        let sample = {
          id: item.id,
          name: item.name,
          scans: item.scans,
        };
        samples.push(sample);
      })
      this.setState((prevState, props) => {
        return {
          selectedSamples: samples,
        };
      }, () => this.generateScansFromSamplesSelected());
    } else {
      // Has selected samples
      let hasScans = Object.entries(this.state.selectedSamples[0].scans).length > 0;
      if (this.state.selectedSamples.length === value.length) {
        this.setState({
          selectedSamples: [],
          chartData: {},
          labelData: "",
          lineColorSet: false,
        });
      } else if (this.state.selectedSamples.length >= 1 && !hasScans) {
        value.forEach(item => {
          let sample = {
            id: item.id,
            name: item.name,
            scans: item.scans,
          };
          samples.push(sample);
        })
        this.setState((prevState, props) => {
          return {
            selectedSamples: samples,
          };
        }, () => this.generateScansFromSamplesSelected());
      } else if (this.state.selectedSamples.length >= 1 && hasScans) {
        value.forEach(item => {
          let sample = {
            id: item.id,
            name: item.name,
            scans: item.scans,
          };
          samples.push(sample);
        })
        this.setState((prevState, props) => {
          return {
            selectedSamples: samples,
          };
        }, () => this.generateScansFromSamplesSelected());
      } else {
        this.setState({
          selectedSamples: [],
          chartData: {},
          labelData: "",
          lineColorSet: false,
        });
      }
    }
  }

  private generateScansFromSamplesSelected() {
    let golden_ratio_conjugate = 0.618033988749895;
    let hue = 0;
    let selectedSamples = this.state.selectedSamples;
    // console.log('selectedSamples', selectedSamples);
    if (Object.entries(selectedSamples).length > 0) {
      console.warn("------------selectedSamples", selectedSamples);
      let foundScanData = selectedSamples.find(sample => sample.scans.length >= 1);
      console.log('foundScanData', foundScanData)
      let labelData;
      let chartData: any;
      if (!foundScanData) {
        this.setState({
          chartData: {},
          labelData: "",
        });
      } else {
        const convertedLegacyAnalysisData = this.convertAnalysisDataLegacy(foundScanData.scans[0]?.data_json);
        console.log('convertedLegacyAnalysisData', convertedLegacyAnalysisData)
        labelData = foundScanData ? convertedLegacyAnalysisData[0]["Test Spectrum"].map((x: any) => x[0]) : "";
        chartData = {
          labels: labelData,
          datasets: [],
        };
        let datasets: any[] = [];
        const test_spectrum_options = {
          fill: "start",
          backgroundColor: "transparent",
          pointBackgroundColor: "#ffffff",
          borderWidth: 1.5,
          pointRadius: 0,
          pointHoverRadius: 3
        };
        const match_spectrum_options = {
          fill: "start",
          backgroundColor: "transparent",
          pointBackgroundColor: "#ffffff",
          borderDash: [3, 3],
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 2,
        };
        // A sample can have many scans
        selectedSamples.forEach((sample, oindex) => {
          // All scans of a sample
          let scans: any[] = sample.scans;
          // console.warn("------ scans all", scans);
          if (scans.length > 0) {
            scans.forEach((scan, index) => {
              let e_scan: any = this.convertAnalysisDataLegacy(scan.data_json);
              // console.log("e_scan", e_scan);
              let test_spectrums: any[] = e_scan["Test Spectrum"];
              let match_spectrums: any[] = e_scan["Match Spectrum"];
              if (!test_spectrums) {
                test_spectrums = [];
              }
              if (!match_spectrums) {
                match_spectrums = [];
              }
              let color = index * 80;
              let dataset = [];
              let test_spectrum_list: any[] = [];
              let match_spectrum_list: any[] = [];

              const getSelectedTestSpectrum = (selectedData: any) => {
                if (e_scan && e_scan.length > 0 && e_scan[selectedData] && e_scan[selectedData]["Test Spectrum"]) {
                  return e_scan[selectedData]["Test Spectrum"].map((x: any) => x[1]);
                } else {
                  return null;
                }
              }

              const getSelectedMatchSpectrum = (selectedData: any) => {
                if (e_scan && e_scan.length > 0 && e_scan[selectedData] && e_scan[selectedData]["Match Spectrum"]) {
                  return e_scan[selectedData]["Match Spectrum"].map((x: any) => x[1]);
                } else {
                  return null;
                }
              }
              // For whole list, everything from selected rows (Spectral, Derivative, PLSDA)
              /*           Object.values(test_spectrums).map((spectrum: any, index) => {
                          console.log("this spectrum", spectrum);
                          if (spectrum === undefined) {
                            spectrum = [];
                          }
                          let test_spectrum = {
                            label: "Test Spectrum Sample" + scan.id + " Scan" + index,
                            fill: "start",
                            data: spectrum[1],
                            backgroundColor: "transparent",
                            borderColor: `rgba(${color}0,123,${color},1)`,
                            pointBackgroundColor: "#ffffff",
                            pointHoverBackgroundColor: "rgb(0,123,255)",
                            borderWidth: 1.5,
                            pointRadius: 0,
                            pointHoverRadius: 3
                          };
                          test_spectrum_list.push(test_spectrum);
                        });
                        Object.values(match_spectrums).map((spectrum: any, index) => {
                          if (spectrum === undefined) {
                            spectrum = [];
                          }
                          let match_spectrum = {
                            label: "Match Spectrum Sample" + scan.id + " Scan" + index,
                            fill: "start",
                            data: spectrum[1],
                            backgroundColor: "transparent",
                            borderColor: "rgba(255,65,105,1)",
                            pointBackgroundColor: "#ffffff",
                            pointHoverBackgroundColor: "rgba(255,65,105,1)",
                            borderDash: [3, 3],
                            borderWidth: 1,
                            pointRadius: 0,
                            pointHoverRadius: 2,
                            pointBorderColor: "rgba(255,65,105,1)"
                          };
                          match_spectrum_list.push(match_spectrum);
                        }); */
              let test_spectrum_color = this.getRandomColor();
              let match_spectrum_color = this.getRandomColor();
              let hsl_test = `hsl(${hue}, 100%, 60%)`;
              let hsl_test2 = `hsl(${hue}, 100%, 25%)`;
              let test_spectrum = {
                label: "Test Spectrum Sample" + scan.id,
                data: getSelectedTestSpectrum(this.state.selectedData),
                borderColor: hsl_test,
                pointHoverBackgroundColor: hsl_test,
                ...test_spectrum_options,
              };
              let match_spectrum = {
                label: "Match Spectrum Sample" + scan.id,
                data: getSelectedMatchSpectrum(this.state.selectedData),
                borderColor: hsl_test2,
                pointHoverBackgroundColor: hsl_test2,
                pointBorderColor: hsl_test2,
                ...match_spectrum_options,
              };
              // Whole List
              //datasets.push(...test_spectrum_list, ...match_spectrum_list);
              datasets.push(test_spectrum, match_spectrum);
              // Conforms to the golden ratio
              hue += 222.5;
            });
          }
        });

        let chartOptions = {};

        chartData.datasets = datasets;
        // console.warn("chartData all", chartData);
        this.setState({
          chartData,
          labelData,
          chartOptions,
          lineColorSet: true,
        });
      }
    } else {
      // Reset Graph
      this.setState({
        chartData: {},
        labelData: "",
        lineColorSet: false,
      });
    }
  }

  private getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  private renderScanButtons() {
    return (
      this.spectralDataLabel.map((item, index) => {
        return (
          <Button
            theme=""
            className={this.state.selectedDataLabel === item ? 'active' : ''}
            onClick={() => this.changeScanData(index)}
          >
            {item}
          </Button>
        );
      })
    );
  }

  private changeScanData(index: number) {
    this.setState((state) => {
      return {
        isLoaded: false,
        selectedDataLabel: this.spectralDataLabel[index],
        selectedData: index,
      }
    }, () => {
      this.setState({
        isLoaded: true,
      })
      this.generateScansFromSamplesSelected()
    })
  }

  private renderSampleList = (samples: Sample[]) => {
    const { prevPath } = this.props.location.state;
    samples.sort((a, b) => a.name.localeCompare(b.name));
    return (
      samples.map((value, key) => {
        return (
          <Card small className="mb-2 css-card" onClick={() => this.setSample(true, value)}>
            <CardBody className="d-flex align-items-start">
              <div>
                {value.image ?
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${value.image!.url}`}
                    alt={value.name}
                    style={{ width: '125px' }}
                  /> :
                  <div style={{ width: '125px', height: '90px', backgroundColor: '#eee' }}></div>
                }
              </div>
              <div className="pl-3 w-100">
                <ListGroup flush>
                  <ListGroupItem className="p-0">
                    <span>
                      <strong>{value.name}</strong>
                    </span>
                    <span className="d-flex justify-content-between">
                      <span>Colour:</span>
                      <strong>{value.colour}</strong>
                    </span>
                    <span className="d-flex justify-content-between">
                      <span>Price Per Item:</span>
                      <strong>{value.price_per_item}</strong>
                    </span>
                    <span className="d-flex justify-content-between">
                      <span>Manufacturer:</span>
                      <strong>{value.manufacturer ? value.manufacturer.name : "N/A"}</strong>
                    </span>
                    <span className="d-flex justify-content-between">
                      <span>Distributor:</span>
                      <strong>{value.distributor ? value.distributor.name : "N?A"}</strong>
                    </span>
                  </ListGroupItem>
                </ListGroup>
              </div>
            </CardBody>
          </Card>
        )
      })
    )
  }

  public render = (): React.ReactNode => {

    const { samples, samplesCount } = this.state;
    const position = { lat: this.state.lat, lng: this.state.lng };

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={this.collection.collection_id}
            subtitle="Collection Information" md="12"
            className="ml-sm-auto mr-sm-auto"
          />
          <strong className="title-info">{`(ID:${this.collection.id})`}</strong>
        </Row>
        <Row>
          <Col sm="8">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="6">
                    <h6 className="m-0">Collection Detail</h6>
                  </Col>
                  <Col lg="6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/*                     <Button onClick={() => this.deleteCollection(this.collection.id)} theme="danger" className="mb-0">
                      Remove Collection
                    </Button> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <CollectionForm
                  collectionInfo={this.collection}
                  submitButtonTitle="Update Collection"
                  onButtonClick={this.updateCollection}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm="4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="12">
                    <h6 className="m-0">Merchant</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pa-2">
                {this.merchant ? (
                  <>
                    <div className="merchant-detail mb-3">
                      <h5>{this.merchant.name}</h5>
                      <div className="address">
                        <span>{this.merchant.address_line1}</span>
                        <span>{this.merchant.address_line2}</span>
                      </div>
                    </div>
                    <div style={{ height: '220px' }}>
                      <Map center={[position.lat, position.lng]} zoom={this.state.zoom}>
                        <TileLayer
                          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position}>
                          <Popup>
                            {this.merchant.name}
                          </Popup>
                        </Marker>
                      </Map>
                    </div>
                  </>) : <span>Merchant not set.</span>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="6" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Samples</h6>
              </CardHeader>
              <CardBody className="p-0">
                <InnerTable
                  onButtonClicked={(value: any) => this.setSample(true, value)}
                  onCheckboxClicked={(index: number, value: object) => this.setSelectedSample(index, value)}
                  onCheckboxAllClicked={(value: any[]) => this.setAllSamples(value)}
                  data={samples}
                  columnsData={sampleTableColumnMini}
                  itemCount={samplesCount}
                  onPaginationActionClicked={this.getNewPageItem}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Graph</h6>
              </CardHeader>
              <CardBody className="pt-4">
                {Object.entries(this.state.chartData).length > 0 ? (
                  <>
                    <div className="spectradata-buttons">
                      {this.renderScanButtons()}
                    </div>
                    <SpectralPlot
                      chartData={this.state.chartData}
                      chartOptions={this.state.chartOptions}
                      height={"320"}
                    />
                  </>) :
                  <span className="p-2">
                    No samples selected for comparison or samples does not contain any scans.
                  </span>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="sample-panel">
          <SlidingPanel
            type={'right'}
            isOpen={this.state.openPanel}
            size={85}
            backdropClicked={() => this.setOpenPanel(false)}
          >
            <div className="close-panel-button" onClick={() => this.setOpenPanel(false)}>
              <i className="material-icons">close</i>
            </div>
            <div>
              <ViewSamplePanel data={this.state.sample} />
            </div>
          </SlidingPanel>
        </div>
      </Container>
    )
  }

}

export default connector(ViewCollection);