import { Manufacturer } from './Manufacturer';
import { Company } from './Company';
import { Merchant } from './Merchant';
import { Collection } from './Collection';
import { IncludeDateData } from './Include';

export interface User extends IncludeDateData {
  id?: number;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  password?: string;
  country?: string;
  manufacturer?: Manufacturer;
  company?: number;
  merchant?: Merchant;
  collection?: Collection;
  role: number;
  blocked?: boolean;
}

export type UserFormData = Omit<User, 'id'|'country'|'manufacturer'|'merchant'|'collection'|'created_at'|'updated_at'>;

export const UserFormState = {
  email: '',
  first_name: '',
  last_name: '',
  username: '',
  password: '',
  role: 0,
  company: 0,
  blocked: false,
};