import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  FormCheckbox,
  FormSelect,
  Button
} from "shards-react";
import { User, UserFormData, UserFormState, Company } from '../../models';
import { FormValidator, messages, regexes } from './FormValidator';
import ShowErrorMessages from './ShowErrorMesssages';
import Noty from 'noty';
import { authApiCall } from "../../services/AuthApiCallService";
import { connect, ConnectedProps } from "react-redux";
interface State extends UserFormData {
  companies: any,
}
interface ComponentProps {
  isAddNewUser?: boolean;
  userInfo?: any;
  submitButtonTitle: string;
  onButtonClick: (userInfo: State) => void;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type AddNewUserFormProps = ComponentProps & ConnectedProps<typeof connector>;
class AddNewUserForm extends React.Component<AddNewUserFormProps, State> {

  public state: State = {
    ...UserFormState,
    companies: [],
  }

  public formOptions = {
    role: [
      {
        id: 1,
        type: 'Authenticated'
      },
      {
        id: 4,
        type: 'Admin'
      }
    ],
  }

  componentDidMount() {
    // console.warn('userData', this.props.userData);
    console.log("userInfo", this.props.userInfo);
    if (this.props.userInfo) {
      const userInfo = this.props.userInfo;
      //delete userInfo.id;
      delete userInfo.full_name;
      delete userInfo.country;
      //delete userInfo.company;
      delete userInfo.merchant;
      delete userInfo.created_at;
      delete userInfo.updated_at;
      //delete userInfo.first_name;
      //delete userInfo.last_name;
      delete userInfo.syncs;
      delete userInfo.manufacturers;
      delete userInfo.collections;

      this.getFormFieldsOnline('companies')
        .then((res: any) => {
          let companyArr: any[] = [];
          res.forEach((company: Company) => {
            let companyData = {
              id: company.id,
              company_name: company.company_name,
            };
            companyArr.push(companyData);
          });
          // Set fields before setting userInfo
          this.setState({
            companies: companyArr,
          }, () => {
            this.setState({
              ...userInfo,
              company: userInfo.company? userInfo.company.id : 0,
              role: userInfo.role.id,
            });
          });
        }).catch(err => {
          console.warn(err);
        });
    } else {
      this.getFormFieldsOnline('companies')
        .then((res: any) => {
          let companyArr: any[] = [];
          res.forEach((company: Company) => {
            let companyData = {
              id: company.id,
              company_name: company.company_name,
            };
            companyArr.push(companyData);
          });
          this.setState({
            companies: companyArr,
          });
        }).catch(err => {
          console.warn(err);
        });
    }
  }

  private getFormFieldsOnline(apiRoute: string) {
    return new Promise((resolve, reject) => {
      authApiCall.get(`/${apiRoute}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.warn(error);
          reject(error);
        })
    });
  }

  private handleChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value } as any);
  }

  private handleIsBlocked = () => {
    this.setState(prevState => {
      return {
        blocked: !prevState.blocked
      }
    });
  }

  private formSubmission = () => {
    // console.log(this.state, this.props.userData);
    const { email, username, first_name, last_name, password, company, role, blocked } = this.state;
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(this.state);
    if (email && username !== '') {
      if (email.match(regex)) {
        let userDetails: any = {
          email,
          username,
          first_name,
          last_name,
          blocked,
        };
        if(company != 0) {
          userDetails.company = company
        }
        if(password) {
          userDetails.password = password;
        }
        if(role) {
          userDetails.role = role;
        }
        this.props.onButtonClick(userDetails)
      } else {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'error',
          text: 'Please enter a valid email.',
          killer: true,
        }).show();
      }
    } else {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: 'Field cannot be empty.',
        killer: true,
      }).show();
    }
  }

  public render(): React.ReactNode {

    const currentAccUser = this.props.userData ? this.props.userData.user.role.type : '';
    const currentUser = this.props.userInfo ? this.props.userInfo.role.type : '';

    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <FormValidator>
                {(formVal: any) => (
                  <Form onSubmit={formVal.handleSubmit(this.formSubmission)}>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          className={`form-control ${formVal.errors.email ? "is-invalid" : ''}`}
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Email"
                          defaultValue={this.state.email ? this.state.email : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({
                            required: messages.required,
                            pattern: {
                              value: regexes.email,
                              message: messages.email,
                            }
                          })}
                          disabled={!this.props.isAddNewUser}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="email" />
                      </Col>
                      <Col md="6">
                        <label htmlFor="username">Username</label>
                        <input
                          className={`form-control ${formVal.errors.username ? "is-invalid" : ''}`}
                          id="username"
                          type="text"
                          name="username"
                          placeholder="Username"
                          defaultValue={this.state.username ? this.state.username : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                          disabled={!this.props.isAddNewUser}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="username" />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          className={`form-control ${formVal.errors.first_name ? "is-invalid" : ''}`}
                          id="first_name"
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          defaultValue={this.state.first_name ? this.state.first_name : ''}
                          onChange={this.handleChange}
                          ref={formVal.register()}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="first_name" />
                      </Col>
                      <Col md="6">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          className={`form-control ${formVal.errors.last_name ? "is-invalid" : ''}`}
                          id="last_name"
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          defaultValue={this.state.last_name ? this.state.last_name : ''}
                          onChange={this.handleChange}
                          ref={formVal.register()}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="last_name" />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          className={`form-control ${formVal.errors.password ? "is-invalid" : ''}`}
                          id="password"
                          type="password"
                          name="password"
                          placeholder="Password"
                          defaultValue={this.state.password ? this.state.password : ''}
                          onChange={this.handleChange}
                          ref={formVal.register()}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="password" />
                      </Col>
                      {!(currentAccUser === "admin" && currentUser === "admin") && (
                        <>
                          <Col md="6" className="form-group">
                            <label htmlFor="role">Role</label>
                            <select
                              className={`form-control ${formVal.errors.role ? "is-invalid" : ''}`}
                              id="role"
                              name="role"
                              placeholder="User Role"
                              defaultValue={this.props.userInfo && this.props.userInfo.role ? this.props.userInfo.role.id: ''}
                              onChange={this.handleChange}
                              ref={formVal.register({ required: messages.required })}
                            >
                              <option value="" disabled key="none">Select user role</option>
                              {
                                this.formOptions.role.map((value, key) => {
                                  return (
                                    <option value={value.id} key={key}>{value.type}</option>
                                  );
                                })
                              }
                            </select>
                            <ShowErrorMessages errors={formVal.errors} name="role" />
                          </Col>
                        </>
                      )}
                    </Row>

                    <Row form>
                      {currentAccUser === 'superadmin' && this.state.companies.length > 0 && (
                        <>
                          <Col md="6" className="form-group">
                            <label htmlFor="company">Company</label>
                            <select
                              className={`form-control ${formVal.errors.company ? "is-invalid" : ''}`}
                              id="company"
                              name="company"
                              placeholder="Select Company"
                              defaultValue={this.props.userInfo && this.props.userInfo.company ? this.props.userInfo.company.id : ''}
                              onChange={this.handleChange}
                              ref={formVal.register({ required: messages.required })}
                            >
                              <option value="" disabled key="none">Select company</option>
                              {
                                this.state.companies.map((value: any, key: number) => {
                                  return (
                                    <option value={value.id} key={key}>{value.company_name}</option>
                                  );
                                })
                              }
                            </select>
                            <ShowErrorMessages errors={formVal.errors} name="company" />
                          </Col>
                        </>
                      )}
                      {!(currentAccUser === "admin" && currentUser === "admin") && (
                        <>
                          <Col md="6" className="form-group">
                            <label htmlFor="transparency">Block user</label>
                            <FormCheckbox
                              name="transparency"
                              checked={this.state.blocked}
                              onChange={this.handleIsBlocked}
                              toggle
                            />
                          </Col>
                        </>
                      )}
                    </Row>

                    <Button type="submit" className="mr-0">{this.props.submitButtonTitle}</Button>
                  </Form>
                )}
              </FormValidator>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
  }
}

export default connector(AddNewUserForm);
