import React from 'react';
//import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { Sample } from '../../models';
import { sampleTableColumn } from '../../components/tables/tableColumn';
import { RouteConstants } from '../../constants/RouteConstants';
import { connect, ConnectedProps } from 'react-redux';

interface State {
  samples: any[];
  sampleDeleted: boolean;
}

interface ComponentProps {
  samples: Sample | undefined;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type SampleProps = ComponentProps & ConnectedProps<typeof connector>;

class Samples extends React.Component<SampleProps, State> {

  public state: State = {
    samples: [],
    sampleDeleted: false,
  }

  componentDidMount() {
    authApiCall.get(`/samples/findByUserID/${this.props.userData.user.id}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ samples: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  public render(): React.ReactNode {
    const { samples } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="12">
            <PageTitle title="Samples" subtitle="All samples" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Sample List</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  itemCount={10}
                  path={RouteConstants.SAMPLES}
                  data={samples}
                  columnsData={sampleTableColumn}
                  onPaginationActionClicked={() => console.log('clicked')}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default connector(Samples);