import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Progress,
  Button
} from 'shards-react';
import { RouteComponentProps } from 'react-router-dom';
import Noty from 'noty';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { Scan } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';

export default class ViewScan extends React.Component<RouteComponentProps> {

  private scan: Scan = this.props.location.state.data;

  private updateScan = (scan: any) => {
    authApiCall.put(`/spectradata/${this.scan.data_json.id}`, {
      ...scan
    })
      .then(response => {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'success',
          text: 'Scan updated.',
          killer: true,
        }).show();
        //Replace the location state of the current route
        this.props.history.replace(this.props.location.pathname, { data: { ...response.data } });

        //2 Options:
        //1) Manually refresh the browser
        window.location.reload();
        //2) redirect back to main list
        //this.props.history.goBack();
      }).catch(error => {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'error',
          text: error,
          killer: true,
        }).show();
      });
  }

  private deleteScan = (id: any) => {
    let deletion = new Noty({
      theme: 'sunset',
      layout: 'topRight',
      text: 'Do you want to continue?',
      buttons: [
        Noty.button('YES', 'btn btn-success', () => {
          authApiCall.delete(`/spectradata/${id}`)
            .then(response => {
              deletion.close();
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'warning',
                text: 'Scan Deleted',
                killer: true,
              }).show();
              this.props.history.push(RouteConstants.SCANS, { scanDeleted: true });
            }).catch(error => {
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'error',
                text: error.message,
                killer: true,
              }).show();
              deletion.close();
            });
        }, { id: 'button1', 'data-status': 'ok' }),

        Noty.button('NO', 'btn btn-danger', () => {
          deletion.close();
        })
      ]
    });
    deletion.show();
  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={this.scan.data_json.id + ` (ID:${this.scan.data_json.id})`}
            subtitle="Scan Profile" md="12"
            className="ml-sm-auto mr-sm-auto"
          />
        </Row>
        <Row>
          <Col lg="12" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="6">
                    <h6 className="m-0">Scan Detail</h6>
                  </Col>
                  <Col lg="6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.deleteScan(this.scan.data_json.id)} theme="danger" className="mb-0">
                      Remove Scan
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <Col lg="6" className="mb-5">
                    <h5>Spectral Data</h5>
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Comparison Graph:</strong>
                          {this.scan.data_json.data_json.spectral_comparison_graph}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Matched Type:</strong>
                          {this.scan.data_json.spectral_matched_type}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Matched Manufacturer:</strong>
                          {this.scan.data_json.spectral_matched_manufacturer.name}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Corellation Score:</strong>
                          {this.scan.data_json.spectral_correlation_score}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                  <Col lg="6" className="mb-5">
                    <h5>Derivative Data</h5>
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Comparison Graph:</strong>
                          {this.scan.data_json.derivative_comparison_graph}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Matched Type:</strong>
                          {this.scan.data_json.derivative_matched_type}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Matched Manufacturer:</strong>
                          {this.scan.data_json.derivative_matched_manufacturer.name}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Corellation Score:</strong>
                          {this.scan.data_json.derivative_correlation_score}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="mb-5">
                    <h5>Plsda Data</h5>
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Comparison Graph:</strong>
                          {this.scan.data_json.plsda_comparison_graph}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Matched Type:</strong>
                          {this.scan.data_json.plsda_matched_type}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Matched Manufacturer:</strong>
                          {this.scan.data_json.plsda_matched_manufacturer.name}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Corellation Score:</strong>
                          {this.scan.data_json.plsda_correlation_score}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                  <Col lg="6" className="mb-5">
                    <h5>Others</h5>
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Environmental Damage Score:</strong>
                          {this.scan.data_json.environmental_damage_score}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Biodegradability Score:</strong>
                          {this.scan.data_json.biodegradability_score}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Material Composition:</strong>
                          {this.scan.data_json.material_composition}
                        </span>
                        <span className="d-flex mb-2">
                          <strong className="mr-1">Summary Report:</strong>
                          {this.scan.data_json.summary_report}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
                {/*JSON.stringify(this.scan.data_json)*/}
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    )
  }

}