export const sampleTableColumn = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Collection Name',
        accessor: 'collection.collection_id',
      },
      {
        Header: 'No. of Scans',
        accessor: 'spectradata.length',
        /*Cell: ({row}: any) => {
          const length = row.original.spectradata.length;
          return length > 0 ? length : '-';
        }*/
      },
      {
        id: 'createdAt',
        Header: 'Created At',
        accessor: 'sample_created_at',
      },
      {
        id: 'buttonClick',
        Header: '',
      },
    ],
  },
];