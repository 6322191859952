import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  FormGroup,
  FormCheckbox,
  FormSelect,
  Button
} from "shards-react";
import {
  Company,
  CompanyFormData,
  CompanyFormState
} from '../../models';
import { FormValidator, messages } from './FormValidator';
import ShowErrorMessages from './ShowErrorMesssages';
import Noty from 'noty';

interface State extends CompanyFormData { }

interface ComponentProps {
  companyInfo?: Company;
  submitButtonTitle: string;
  onButtonClick: (companyInfo: CompanyFormData) => void;
}

class AddNewCompanyForm extends React.Component<ComponentProps, CompanyFormData> {

  public state: CompanyFormData = {
    ...CompanyFormState
  }

  componentWillMount() {
    if (this.props.companyInfo) {
      const companyInfo = this.props.companyInfo;
      this.setState({ ...companyInfo })
    }
  }

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value } as Pick<CompanyFormData, keyof CompanyFormData>);
  }

  private formSubmission = () => {
    const { company_name } = this.state;

    if (company_name !== '') {
      this.props.onButtonClick(this.state)
    } else {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: 'Company name cannot be empty.',
        killer: true,
      }).show();
    }
  }

  public render(): React.ReactNode {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <FormValidator>
                {(formVal: any) => (
                  <Form onSubmit={formVal.handleSubmit(this.formSubmission)}>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="company_name">Company Name</label>
                        <input
                          className={`form-control ${formVal.errors.company_name ? "is-invalid" : ''}`}
                          id="company_name"
                          type="text"
                          name="company_name"
                          placeholder="Company Name"
                          defaultValue={this.state.company_name ? this.state.company_name : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="company_name" />
                      </Col>
                      <Col md="6" className="form-group"></Col>
                    </Row>

                    <Button type="submit" className="mr-0">{this.props.submitButtonTitle}</Button>
                  </Form>
                )}
              </FormValidator>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
  }
}

export default AddNewCompanyForm;
