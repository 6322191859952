import React, { useState, useEffect, FunctionComponent } from 'react';
import { Button } from 'shards-react';

interface PaginationProps {
  itemCount: number;
  perPageLimit: number;
  paginationCurrentPage: number;
  onNextPage: (currentPage: number, startAt: number) => void;
  onPrevPage: (currentPage: number, startAt: number) => void;
  onPageNumberClick: (currentPage: number, startAt: number) => void;
}

export const Pagination: FunctionComponent<PaginationProps> = ({
  itemCount,
  perPageLimit,
  paginationCurrentPage,
  onNextPage,
  onPrevPage,
  onPageNumberClick
}) => {

  // State
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [prevDisabled, setPrevDisabled] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (itemCount && perPageLimit) {
      const pages: number = Math.ceil(itemCount / perPageLimit);
      setNumberOfPages(pages);
    }
  }, [itemCount, perPageLimit]);

  useEffect(() => {
    if (paginationCurrentPage) {
      // console.log(paginationCurrentPage, numberOfPages);
      setCurrentPage(paginationCurrentPage);
      setActivePage(paginationCurrentPage);
      if (numberOfPages === 1) {
        setPrevDisabled(true);
        setNextDisabled(true);
      } else {
        if (paginationCurrentPage === numberOfPages) {
          setPrevDisabled(false);
          setNextDisabled(true);
        } else if (paginationCurrentPage === 1) {
          setPrevDisabled(true);
          setNextDisabled(false);
        } else {
          setPrevDisabled(false);
          setNextDisabled(false);
        }
      }
    }
  }, [paginationCurrentPage, numberOfPages])

  const toPrevPage = () => {
    if (currentPage > 1) {
      const newPage: number = currentPage - 1;
      const startAt: number = perPageLimit * (newPage - 1);
      onPrevPage(newPage, startAt);
    }
  }

  const toNextPage = () => {
    if (currentPage < numberOfPages) {
      const newPage: number = currentPage + 1;
      const startAt: number = perPageLimit * (newPage - 1);
      onNextPage(newPage, startAt);
    }
  }

  const toPage = (pageNumber: number) => {
    const startAt: number = perPageLimit * pageNumber;
    onPageNumberClick(pageNumber + 1, startAt);
  }

  const RenderPagesNumberButtons = () => {
    return (
      <div className="num-buttons">
        {/* <div>Number of pages: {numberOfPages}</div>
        <div>currentPage: {currentPage}</div>
        <div>activePage: {activePage}</div> */}
        {numberOfPages > 9 ? (
          <>
            {/* First Page */}
            <Button outline size="sm" active={currentPage == 1} className="num-button" onClick={() => toPage(0)}>1</Button>
            {currentPage > 5 && currentPage < (numberOfPages - 4) ? (
              <>
                <span className="pagination-add">...</span>
                <Button outline size="sm" className="num-button" onClick={() => toPage(currentPage - 4)}>{currentPage - 3}</Button>
                <Button outline size="sm" className="num-button" onClick={() => toPage(currentPage - 3)}>{currentPage - 2}</Button>
                <Button outline size="sm" className="num-button" onClick={() => toPage(currentPage - 2)}>{currentPage - 1}</Button>
                <Button outline size="sm" active={true} className="num-button" onClick={() => toPage(currentPage - 1)}>{currentPage}</Button>
                <Button outline size="sm" className="num-button" onClick={() => toPage(currentPage)}>{currentPage + 1}</Button>
                <Button outline size="sm" className="num-button" onClick={() => toPage(currentPage + 1)}>{currentPage + 2}</Button>
                <Button outline size="sm" className="num-button" onClick={() => toPage(currentPage + 2)}>{currentPage + 3}</Button>
                <span className="pagination-add">...</span>
              </>
            ) : (
              <>
                {currentPage + 4 >= numberOfPages ? (
                  <>
                    {currentPage == numberOfPages ? (
                      <>
                        <span className="pagination-add">...</span>
                        {[...new Array(4)].map((item, index) => {
                          return (
                            <Button outline size="sm" className="num-button" onClick={() => toPage((currentPage - 1) - (4 - index))}>{currentPage - (4 - index)}</Button>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        <span className="pagination-add">...</span>
                        {[...new Array(4)].map((item, index) => {
                          return (
                            <Button outline size="sm" className="num-button" onClick={() => toPage((currentPage - 1) + (index - 4))}>{currentPage + (index - 4)}</Button>
                          )
                        })}
                        {[...new Array(numberOfPages % currentPage)].map((item, index) => {
                          return (
                            <Button outline size="sm" active={(currentPage + index) == activePage} className="num-button" onClick={() => toPage((currentPage - 1) + index)}>{currentPage + index}</Button>
                          )
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {currentPage > 1 ? (
                      <>
                        {currentPage <= 5 ? (
                          <>
                            {[...new Array(currentPage - 2)].map((item, index) => {
                              return (
                                <Button outline size="sm" className="num-button" onClick={() => toPage((currentPage - 1) + (index - (currentPage - 2)))}>{currentPage + (index - (currentPage - 2))}</Button>
                              )
                            })}
                          </>
                        ) : (
                          <span className="pagination-add">...</span>
                        )}
                        {[...new Array(4)].map((item, index) => {
                          return (
                            <Button outline size="sm" active={currentPage - index == activePage} className="num-button" onClick={() => toPage((currentPage - 1) + index)}>{currentPage + index}</Button>
                          )
                        })}
                        <span className="pagination-add">...</span>
                      </>
                    ) : (
                      <>
                        {[...new Array(currentPage + 4)].map((item, index) => {
                          if (currentPage !== index + 1) {
                            return (
                              <Button outline size="sm" active={currentPage - index == activePage} className="num-button" onClick={() => toPage((currentPage - 1) + index)}>{currentPage + index}</Button>
                            )
                          }
                        })}
                        <span className="pagination-add">...</span>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {/* Last Page */}
            <Button outline size="sm" active={currentPage == numberOfPages} className="num-button" onClick={() => toPage(numberOfPages - 1)}>{numberOfPages}</Button>
          </>
        ) : (
          <>
            {/* Minimum show all page before minimise */}
            {
              [...new Array(numberOfPages)].map((item, index) => {
                return (
                  <Button outline size="sm" active={currentPage == index + 1} className="num-button" onClick={() => toPage(index)}>{index + 1}</Button>
                )
              })
            }
          </>
        )
        }
      </div >
    )
  }

  return (
    <>
      {/* <div className="pagination-debug">
        <div>Item Count: {itemCount}</div>
        <div>Per Page Limit: {perPageLimit}</div>
        <div>Current Page: {currentPage}</div>
        <div>Number of Pages: {numberOfPages} (round up --- Item Count / Per Page Limit)</div>
      </div> */}
      <div className="pagination-actions">
        <Button outline size="sm" className="prev-button" onClick={toPrevPage} disabled={prevDisabled}>Prev</Button>
        <RenderPagesNumberButtons />
        <Button outline size="sm" className="next-button" onClick={toNextPage} disabled={nextDisabled}>Next</Button>
      </div>
    </>
  )

}