import React from 'react';
import { Link, RouteComponentProps } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { collectionTableColumn } from '../../components/tables//tableColumn';
import { Collection } from '../../models/Collection';
import { RouteConstants } from '../../constants/RouteConstants';
import { connect, ConnectedProps } from 'react-redux';

interface State {
  collections: Collection[];
  collectionsCount: number;
  collectionDeleted: boolean;
}

interface ComponentProps {
  collections: Collection;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type CollectionsProps = RouteComponentProps & ComponentProps & ConnectedProps<typeof connector>;

class Collections extends React.Component<CollectionsProps, State> {

  constructor(props: any) {
    super(props);
    this.getNewPageItem = this.getNewPageItem.bind(this);
  }

  public state: State = {
    collections: [],
    collectionsCount: 0,
    collectionDeleted: false,
  }

  componentDidMount() {
    // console.warn('user id', this.props.userData);
    // console.warn("store object", this.props);
    authApiCall.get(`/collections/count/`)
      .then(response => {
        // console.log(response.data);
        this.setState({ collectionsCount: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  private getNewPageItem(perPageLimit: number, startAt: number) {
    authApiCall.get(`/collections/findByUserID/${this.props.userData.user.id}?_limit=${perPageLimit}&_start=${startAt}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ collections: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  public render(): React.ReactNode {
    const { collections, collectionsCount } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="12">
            <PageTitle title="Collections" subtitle="All Collections" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
          {/*           <Col lg="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Link style={{ color: '#ffffff' }} to={RouteConstants.ADD_COLLECTION}>
              <Button theme="primary" className="mb-0 mr-0">Create Collection</Button>
            </Link>
          </Col> */}
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Current collections</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  path={RouteConstants.COLLECTIONS}
                  data={collections}
                  columnsData={collectionTableColumn}
                  itemCount={collectionsCount}
                  actionType={String(this.props.history.action)}
                  onPaginationActionClicked={this.getNewPageItem}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default connector(Collections);