import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Progress,
  Button,
  Table,
} from 'shards-react';
import Noty from 'noty';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import axios from 'axios';
import { Scan } from '../../models';
import SpectralPlot from './SpectralPlot';

interface ComponentProps {
  onClosePanel: (close: boolean) => void;
  fromSample: String;
  data: Scan;
}

interface State {
  isLoaded: boolean;
  selectedDataLabel: string;
  selectedData: number;
  scanData: any;
  test_spectrum: any;
  match_spectrum: any;
  scanLabels: number[];
}

type ViewScanPanelProps = ComponentProps;

export default class ViewScanPanel extends React.Component<ViewScanPanelProps, State> {

  constructor(props: any) {
    super(props);
    this.getScanDataSingleValue = this.getScanDataSingleValue.bind(this);
    this.getSelectedMatchSpectrum = this.getSelectedMatchSpectrum.bind(this);
    this.getSelectedTestSpectrum = this.getSelectedTestSpectrum.bind(this);
  }

  private spectralDataLabel = ['Spectral', 'Derivative', 'PLSDA', 'SVM', 'Random Forest'];

  public state: State = {
    isLoaded: false,
    selectedDataLabel: this.spectralDataLabel[0],
    selectedData: 0,
    scanData: {},
    test_spectrum: {},
    match_spectrum: {},
    scanLabels: [],
  }

  componentDidMount() {
    // console.log('props', this.props);
    // console.log('typeof', typeof this.props.data.data_json);
    // Checks if the data is using legacy data and converts it to the new format
    const convertedLegacyAnalysisData = this.convertAnalysisDataLegacy(this.props.data.data_json);

    this.setState({
      isLoaded: true,
      scanData: convertedLegacyAnalysisData,
      test_spectrum: convertedLegacyAnalysisData["Test Spectrum"],
      match_spectrum: convertedLegacyAnalysisData["Match Spectrum"],
      scanLabels: convertedLegacyAnalysisData[0]["Test Spectrum"].map((x: any) => x[0])
    }, () => {
      console.warn("scanData", this.state.scanData);
      console.warn("Test Spectrum", this.state.test_spectrum);
      console.warn("Match Spectrum", this.state.match_spectrum);
    })

  }

  private convertAnalysisDataLegacy(data: any) {
    // console.warn('---analysis data length', data, data.length, typeof data)
    if (data && data.length == undefined) {
      let outData = [];
      let keys = Object.keys(data);
      let numModels = data["ID Method"] ? Object.keys(data["ID Method"]).length : 0;
      for (let i = 0; i < numModels; i++) {
        let obj: any = {};
        for (let k = 0; k < keys.length; k++) {
          if (keys[k] == "Test Spectrum" || keys[k] == "Match Spectrum") {
            // x,y values were split into 2 arrays, combine into single array with nested x,y array values
            let spectrumData = data[keys[k]][i];
            if (spectrumData[0] && spectrumData[0].length > 0) {
              let slength = spectrumData[0].length;
              let tmpArray = [];
              for (let s = 0; s < slength; s++) {
                tmpArray.push([spectrumData[0][s], spectrumData[1][s]])
              }
              obj[keys[k]] = tmpArray;
            }
          } else {
            obj[keys[k]] = data[keys[k]][i];
          }
        }
        outData.push(obj);
      }
      data = outData;
    }
    return data;
  }

  private updateScan = (scan: any) => {
    authApiCall.put(`/spectradata/${this.props.data.id}`, {
      ...scan
    })
      .then(response => {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'success',
          text: 'Scan updated.',
          killer: true,
        }).show();
        //Replace the location state of the current route
        //this.props.history.replace(this.props.location.pathname, { data: { ...response.data } });

        //2 Options:
        //1) Manually refresh the browser
        window.location.reload();
        //2) redirect back to main list
        //this.props.history.goBack();
      }).catch(error => {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'error',
          text: error,
          killer: true,
        }).show();
      });
  }

  private deleteScan = (id: any) => {
    let deletion = new Noty({
      theme: 'sunset',
      layout: 'topRight',
      text: 'Do you want to continue?',
      buttons: [
        Noty.button('YES', 'btn btn-success', () => {
          authApiCall.delete(`/spectradata/${id}`)
            .then(response => {
              deletion.close();
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'warning',
                text: 'Scan Deleted',
                killer: true,
              }).show();
              //this.props.history.push(RouteConstants.SCANS, { scanDeleted: true });
            }).catch(error => {
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'error',
                text: error.message,
                killer: true,
              }).show();
              deletion.close();
            });
        }, { id: 'button1', 'data-status': 'ok' }),

        Noty.button('NO', 'btn btn-danger', () => {
          deletion.close();
        })
      ]
    });
    deletion.show();
  }

  private renderScanButtons() {
    let count = this.state.scanData.length;
    return (
      this.spectralDataLabel.map((item, index) => {
        if(index < count){
          return (
            <Button
              theme=""
              className={this.state.selectedDataLabel === item ? 'active' : ''}
              onClick={() => this.changeScanData(index)}
            >
              {item}
            </Button>
          );
        }
      })
    );
  }

  private changeScanData(index: number) {
    this.setState((state) => {
      return {
        isLoaded: false,
        selectedDataLabel: this.spectralDataLabel[index],
        selectedData: index,
      }
    }, () => {
      this.setState({
        isLoaded: true,
      })
    })
  }

  /*   csvOutput(thedata, type){
      if(!thedata) thedata = this.ds.getReadingData();
      // output to csv
      let filename = `scan-${type}.csv`;
      let csvContent = `data:text/csv;charset=utf-8,`;
      thedata.forEach(function(rowArray) {
          let row = rowArray.join(',');
          csvContent += row + '\r\n';
      });
      let encodedUri = encodeURI(csvContent);
      // window.open(encodedUri);
      // window.location.assign(encodedUri);
      this.createDownloadElement(encodedUri, filename );
    } */

  private csvOutputSpectrum(thedata: any, type: any) {
    // console.warn(thedata, typeof thedata);
    let filename = `spectrum-${type}.csv`;
    let csvContent = `data:text/csv;charset=utf-8,`;
    for (let [key, val] of Object.entries(thedata)) {
      let rowArray = thedata[key];
      if (Array.isArray(rowArray)) {
        let row = rowArray.join(',');
        csvContent += row + '\r\n';
      }
    }
    let encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
    // window.location.assign(encodedUri);
    this.createDownloadElement(encodedUri, filename);
  }

  private createDownloadElement(uri: any, filename: any) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;

      //Firefox requires the link to be in the body
      document.body.appendChild(link);

      //simulate click
      link.click();

      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }

  private getScanDataSingleValue(key: string) {
    if (this.state.scanData[this.state.selectedData]?.[key]) {
      return this.state.scanData[this.state.selectedData][key];
    } else {
      return null;
    }
  }

  private getSelectedTestSpectrum(selectedData: any) {
    if (this.state.scanData[selectedData]?.["Test Spectrum"]) {
      return this.state.scanData[selectedData]["Test Spectrum"].map((x: any) => x[1]);
    } else {
      return null;
    }
  }

  private getSelectedMatchSpectrum(selectedData: any) {
    if (this.state.scanData[selectedData]?.["Match Spectrum"]) {
      return this.state.scanData[selectedData]["Match Spectrum"].map((x: any) => x[1]);
    } else {
      return null;
    }
  }

  public render(): React.ReactNode {
    if (this.state.scanData.length !== 0) {
      return (
        <Container fluid className="main-content-container px-4">
          <Row noGutters>
            <Col>
              <div className="panel-navigator">
                <span
                  className="panel-navlink"
                  onClick={() => this.props.onClosePanel(false)}
                >
                  {this.props.fromSample}
                </span> / {this.props.data.id}
              </div>
            </Col>
          </Row>
          <Row noGutters className="page-header py-4">
            <PageTitle
              title={`Scan ${this.props.data.id}`}
              subtitle="Scan Profile" md="12"
              className="ml-sm-auto mr-sm-auto"
            />
            <strong className="title-info">
              {`(ID:${this.props.data.id})`}
            </strong>
          </Row>
          <Row noGutters>
            <div className="spectradata-buttons w-100">
              {this.renderScanButtons()}
            </div>
          </Row>
          <Row noGutters>
            <Col lg="4" className="mb-4 pr-2">
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <Row>
                    <Col lg="6">
                      <h5 className="m-0">Scan Detail</h5>
                    </Col>
                    {/*                   <Col lg="6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.deleteScan(this.props.data.id)} theme="danger" className="mb-0">
                      Remove Scan
                    </Button>
                  </Col> */}
                  </Row>
                </CardHeader>
                <CardBody className="p-3">
                  {this.state.isLoaded && this.spectralDataLabel.includes(this.state.selectedDataLabel) && (
                    <>
                      <Row>
                        <Col lg="12" className="mb-5">
                          <h5>Spectral Data</h5>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Matched Type</td>
                                <td className="cell-value">{this.getScanDataSingleValue("Material ID Result")}</td>
                              </tr>
                              <tr>
                                <td>Matched Manufacturer</td>
                                <td className="cell-value">{this.getScanDataSingleValue("Brand ID Result")}</td>
                              </tr>
                              <tr>
                                <td>Material Match Score</td>
                                <td className="cell-value">{this.getScanDataSingleValue("Material Match Score")}</td>
                              </tr>
                              <tr>
                                <td>Brand Match Score</td>
                                <td className="cell-value">{this.getScanDataSingleValue("Brand Match Score")}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="mb-5">
                          <h5>Others</h5>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Correlation</td>
                                <td className="cell-value">{this.getScanDataSingleValue("Outputstrings")}</td>
                              </tr>
                              <tr>
                                <td>Biodegradability Score</td>
                                <td className="cell-value">{this.getScanDataSingleValue("Biodegradability")}</td>
                              </tr>
                              <tr>
                                <td>Biodegradable</td>
                                <td className="cell-value">
                                  {this.getScanDataSingleValue("Biodegradability") === 1 ?
                                    "Yes" : "No"}
                                </td>
                              </tr>
                              <tr>
                                <td>Material Composition</td>
                                <td className="cell-value">{this.getScanDataSingleValue("Material ID Result")}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </>)
                  }
                  {/*JSON.stringify(this.props.data)*/}
                </CardBody>
              </Card>
            </Col>
            <Col lg="8" className="mb-4 pl-2">
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h5 className="m-0">Graph</h5>
                </CardHeader>
                <CardBody>
                  {this.state.isLoaded && (
                    <SpectralPlot
                      chartOptions={this.state.selectedData === 1 ? {
                        scales: {
                          xAxes: [
                            {
                              gridLines: false,
                              ticks: {
                                callback(tick: any, index: number) {
                                  // Jump every 7 values on the X axis labels to avoid clutter.
                                  return index % 1 !== 0 ? "" : tick;
                                }
                              }
                            }
                          ],
                          yAxes: [
                            {
                              ticks: {
                                suggestedMax: 0.02,
                                suggestedMin: -0.02,
                                callback(tick: any) {
                                  if (tick === 0) {
                                    return tick;
                                  }
                                  // Format the amounts using Ks for thousands.
                                  return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                                }
                              }
                            }
                          ]
                        },
                      } : {}}
                      chartData={{
                        labels: this.state.scanLabels,
                        datasets: [
                          {
                            label: "Test Spectrum",
                            fill: "start",
                            data: this.getSelectedTestSpectrum(this.state.selectedData),
                            backgroundColor: "transparent",
                            borderColor: "rgba(0,123,255,1)",
                            pointBackgroundColor: "#ffffff",
                            pointHoverBackgroundColor: "rgb(0,123,255)",
                            borderWidth: 1.5,
                            pointRadius: 0,
                            pointHoverRadius: 3
                          },
                          {
                            label: "Match Spectrum",
                            fill: "start",
                            data: this.getSelectedMatchSpectrum(this.state.selectedData),
                            backgroundColor: "transparent",
                            borderColor: "rgba(255,65,105,1)",
                            pointBackgroundColor: "#ffffff",
                            pointHoverBackgroundColor: "rgba(255,65,105,1)",
                            borderDash: [3, 3],
                            borderWidth: 1,
                            pointRadius: 0,
                            pointHoverRadius: 2,
                            pointBorderColor: "rgba(255,65,105,1)"
                          }
                        ]
                      }} />)
                  }
                </CardBody>
              </Card>
              <Row>
                <Col className="d-flex justify-content-start">
                  <Button
                    theme="primary"
                    className=""
                    onClick={() => this.csvOutputSpectrum(this.state.scanData[this.state.selectedData]['Test Spectrum'], 'test')}
                  >Export Test Spectrum</Button>
                  <Button
                    theme="primary"
                    className="ml-3"
                    onClick={() => this.csvOutputSpectrum(this.state.scanData[this.state.selectedData]['Match Spectrum'], 'match')}
                  >Export Match Spectrum</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )
    } else {
      return (
        <div className="p-4 pl-0">
          <h5>No data sample.</h5>
        </div>
      )
    }
  }

}