import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from 'shards-react';
import { time } from '../../services/convertTime';
import PageTitle from '../../components/common/PageTitle';
import { Sample, SpectraData, Scan } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';
import { authApiCall } from '../../services/AuthApiCallService';
import { showData } from '../../services/checkProperties';
import SlidingPanel from 'react-sliding-side-panel';
import ViewScanPanel from '../Scans/ViewScanPanel';

interface State {
  openPanel: boolean;
  scans: Scan[];
  scan: any;
}

interface ComponentProps {
  data: Sample;
}

type ViewSamplePanelProps = ComponentProps;

class ViewSamplePanel extends React.Component<ViewSamplePanelProps, State> {

  //private sample: Sample = this.props.location.state.data;
  //private data: SpectraData = this.props.location.state.data;
  //private prevPath = this.props.location.state.prevPath;

  public state: State = {
    openPanel: false,
    scans: [],
    scan: {},
  }

  componentDidMount() {
    let scans = this.props.data!.scans;
    if(scans){
      scans.sort((a:any,b:any) => b - a);
      scans.forEach((value: any, key: number) => {
        authApiCall.get(`/scans/${value.id}`)
          .then(response => {
            this.setState((state) => {
              return { scans: [...state.scans, response.data] };
            });
          })
          .catch(error => {
            console.warn(error);
          })
      });
    }
  }

  private setOpenPanel(openPanelState: boolean) {
    this.setState({
      openPanel: openPanelState
    });
  }

  private setScan(openPanelState: boolean, value: Scan) {
    this.setState({
      openPanel: openPanelState,
      scan: value
    });
  }

  private renderScans = (scans: Scan[]) => {
    return (
      scans.map((value, key) => {
        return (
          <Card
            key={value.id}
            small
            className="css-card spectradata-card"
            onClick={() => this.setScan(true, value)}
          >
            <CardBody className="d-flex align-items-start">
              <div>
                <div style={{ width: '125px', height: '90px', backgroundColor: '#eee' }}></div>
              </div>
              <div className="pl-3 w-100">
                <ListGroup flush>
                  <ListGroupItem className="p-0">
                    <span>
                      <strong>{`Scan ${value.id}`}</strong>
                    </span>
                    <span className="d-flex justify-flex-start">
                      <span>Scan Date:</span>
                      <strong className="ml-2">{time(value.created_at)}</strong>
                    </span>
                  </ListGroupItem>
                </ListGroup>
              </div>
            </CardBody>
          </Card>
        )
      })
    )
  }

  public render(): React.ReactNode {

    const { scans } = this.state;
    const sample = this.props.data;
    const sampleName = sample.name.toLowerCase();

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters>
          <Col>
            <div className="panel-navigator">{sample.name}</div>
          </Col>
        </Row>
        <Row noGutters className="page-header py-4 text-center">
          <PageTitle
            title={sample.name}
            subtitle="Sample Information" md="12"
            className=""
          />
          <strong className="title-info">
            {`(ID:${sample.id}, Collection: ${sample.collection.collection_id})`}
          </strong>
        </Row>
        <Row>
          <Col lg="6">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Image</h6>
              </CardHeader>
              <CardBody>
                {sample.image ?
                  <div>
                    <img src={`${process.env.REACT_APP_BASE_URL}${sample.image.url}`} alt="" width="100%" />
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mt-3">
                          <strong className="mr-1">Name:</strong> {sample.image.name}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                  :
                  <span>No image.</span>
                }
              </CardBody>
            </Card>
            <Card small className="mb-4">
              <CardHeader className="d-flex">
                <h6 className="m-0 flex-grow-1">Sample Details</h6>
                <div className="d-flex align-items-center justify-content-end">
                  <Link
                    style={{ color: '#ffffff' }}
                    to={{
                      pathname: `${RouteConstants.SAMPLES}/edit/${sampleName}`,
                      state: { data: { ...sample }, /* prevPath */ }
                    }}
                  >
                    <Button theme="primary" className="mb-0 mr-1">Edit</Button>
                  </Link>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <Row>
                  <Col lg="6">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Thickness</td>
                          <td className="cell-value">{showData(sample.thickness)}</td>
                        </tr>
                        <tr>
                          <td>Strength</td>
                          <td className="cell-value">{showData(sample.strength)}</td>
                        </tr>
                        <tr>
                          <td>Weight</td>
                          <td className="cell-value">{showData(sample.weight)}</td>
                        </tr>
                        <tr>
                          <td>Flexibility</td>
                          <td className="cell-value">{sample.flexibility ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                          <td>Transparency</td>
                          <td className="cell-value">{sample.transparency ? 'Yes' : 'No'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col lg="6">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Width</td>
                          <td className="cell-value">{showData(sample.width)}</td>
                        </tr>
                        <tr>
                          <td>Height</td>
                          <td className="cell-value">{showData(sample.height)}</td>
                        </tr>
                        <tr>
                          <td>Size</td>
                          <td className="cell-value">{showData(sample.size)}</td>
                        </tr>
                        <tr>
                          <td>Shape</td>
                          <td className="cell-value">{sample.shape ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                          <td>Colour</td>
                          <td className="cell-value">{showData(sample.colour)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                {/*                 <ListGroup flush>
                  <ListGroupItem className="p-0">
                    <span className="d-flex justify-content-between mt-3">
                      <span><strong className="mr-1">Thickness:</strong> {showData(sample.thickness)}</span>
                      <span><strong className="mr-1">Strength:</strong> {showData(sample.strength)}</span>
                      <span><strong className="mr-1">Weight:</strong> {showData(sample.weight)}</span>
                    </span>
                    <span className="d-flex justify-content-between mt-3">
                      <span><strong className="mr-1">Flexibility:</strong> {sample.flexibility ? 'Yes' : 'No'}</span>
                      <span><strong className="mr-1">Transparency:</strong> {sample.transparency ? 'Yes' : 'No'}</span>
                      <span><strong className="mr-1">Colour:</strong> {showData(sample.colour)}</span>
                    </span>
                    <span className="d-flex justify-content-between mt-3">
                      <span><strong className="mr-1">Width:</strong> {showData(sample.width)} cm</span>
                      <span><strong className="mr-1">Height:</strong> {showData(sample.height)} cm</span>
                      <span><strong className="mr-1">Size:</strong> {showData(sample.size)} cm<sup>2</sup></span>
                    </span>
                    <span className="d-flex mt-3">
                      <strong className="mr-1">Shape:</strong> {showData(sample.shape)}
                    </span>
                  </ListGroupItem>
                </ListGroup> */}
              </CardBody>
            </Card>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Manufacturer</h6>
                  </CardHeader>
                  <CardBody>
                    {'manufacturer' in sample && sample.manufacturer ?
                      <ListGroup flush>
                        <ListGroupItem className="p-0">
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">store</i>
                            <strong className="mr-1">Name:</strong> {showData(sample.manufacturer.name)}
                          </span>
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">label</i>
                            <strong className="mr-1">Label Name:</strong> {showData(sample.manufacturer.label_name)}
                          </span>
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">language</i>
                            <strong className="mr-1">Website:</strong>
                            <a href={sample.manufacturer.website}>{showData(sample.manufacturer.website)}</a>
                          </span>
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">phone</i>
                            <strong className="mr-1">Tel:</strong> {showData(sample.manufacturer.tel)}
                          </span>
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">book</i>
                            <strong className="mr-1">Address:</strong> {showData(sample.manufacturer.address)}
                          </span>
                        </ListGroupItem>
                      </ListGroup> :
                      <span>No manufacturer assigned.</span>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Distributor</h6>
                  </CardHeader>
                  <CardBody>
                    {'distributor' in sample && sample.distributor ?
                      <ListGroup flush>
                        <ListGroupItem className="p-0">
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">store</i>
                            <strong className="mr-1">Name:</strong> {showData(sample.distributor.name)}
                          </span>
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">language</i>
                            <strong className="mr-1">Website:</strong>
                            <a href={sample.distributor.website}>{showData(sample.distributor.website)}</a>
                          </span>
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">phone</i>
                            <strong className="mr-1">Tel:</strong> {showData(sample.distributor.tel)}
                          </span>
                          <span className="d-flex mb-2">
                            <i className="material-icons mr-1">book</i>
                            <strong className="mr-1">Address:</strong> {showData(sample.distributor.address)}
                          </span>
                        </ListGroupItem>
                      </ListGroup> :
                      <span>No distributor assigned.</span>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Additional Information</h6>
                  </CardHeader>
                  <CardBody>
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">done_outline</i>
                          <strong className="mr-1">Sirim Label:</strong> {showData(sample.sirim_label)}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">eco</i>
                          <strong className="mr-1">Eco Label License:</strong> {showData(sample.eco_label_license)}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">bookmark</i>
                          <strong className="mr-1">Purchase Date:</strong> {time(sample.purchase_date)}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">bookmark</i>
                          <strong className="mr-1">Intended Use:</strong> {showData(sample.intended_use)}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">bookmark</i>
                          <strong className="mr-1">Quantity:</strong> {showData(sample.quantity)}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">access_time</i>
                          <strong className="mr-1">Created At:</strong> {time(sample.sample_created_at)}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Scans</h6>
              </CardHeader>
              <CardBody>
                {
                  scans.length > 0 ?
                    this.renderScans(scans) :
                    <span>There are currently no scans.</span>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="spectradata-panel">
          <SlidingPanel
            type={'right'}
            isOpen={this.state.openPanel}
            size={85}
            backdropClicked={() => this.setOpenPanel(false)}
          >
            <div className="close-panel-button" onClick={() => this.setOpenPanel(false)}>
              <i className="material-icons">close</i>
            </div>
            <div>
              <ViewScanPanel
                onClosePanel={() => this.setOpenPanel(false)}
                fromSample={sample.name}
                data={this.state.scan}
              />
            </div>
          </SlidingPanel>
        </div>
      </Container>
    );
  }

}

export default ViewSamplePanel;