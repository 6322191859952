import moment from 'moment';

export const validateTime = (time, format = 'MMMM Do YYYY, h:mm:ss a') => {
  let validatedTime = moment(time, format).isValid();
  return validatedTime;
}

export const time = (time, format = 'MMMM Do YYYY, h:mm:ss a') => {
  let convertedTime = moment(time).format(format);
  return convertedTime;
}

export const utcTime = (time, format = 'MMMM Do YYYY, h:mm:ss a') => {
  let convertedUtcTime = moment(time).utc();
  convertedUtcTime.hour(0).minute(0).second(0);
  convertedUtcTime.format(format);
  return convertedUtcTime;
}