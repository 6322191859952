import { Company } from './Company';
import { Merchant } from './Merchant';
import { User } from './User';
import { Sample } from './Samples';
import { Moment } from 'moment';

export interface Collection {
  id?: number;
  uuid?: string;
  collection_id: string;
  collection_name: string;
  collection_type: string;
  collection_date: string | Moment;
  collection_time: string | Moment;
  payment_status: string;
  status: string;
  samples: Sample[];
  merchant: Merchant;
  last_updated?: string | Date | null;
  last_synced?: string | Date | null;
  is_synced?: boolean;
  is_deleted?: boolean;
}

export type CollectionFormData = Omit<Collection,
'id'|'uuid'|'collection_name'|'collection_type'|'samples'|'merchant'|'user_id'|
'last_updated'|'last_synced'|'is_synced'|'is_deleted'>;

export const CollectionFormState = {
  collection_id: '',
  collection_date: '',
  collection_time: '',
  payment_status: '',
  status: '',
};