import { RouteConstants } from '../constants/RouteConstants';

export default function() {
  return [
    {
      title: "Dashboard",
      to: RouteConstants.ADMIN,
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: "",
      access: ['admin','authenticated','superadmin'],
    },
    {
      title: "Collections",
      to: RouteConstants.COLLECTIONS,
      htmlBefore: '<i class="material-icons">collections_bookmark</i>',
      htmlAfter: "",
      access: ['admin','authenticated','superadmin'],
    },
/*     {
      title: "Samples",
      to: RouteConstants.SAMPLES,
      htmlBefore: '<i class="material-icons">bookmark</i>',
      htmlAfter: "",
      access: ['admin','authenticated','superadmin'],
    },
    {
      title: "Scans",
      to: RouteConstants.SCANS,
      htmlBefore: '<i class="material-icons">fingerprint</i>',
      htmlAfter: "",
      access: ['admin','authenticated','superadmin'],
    }, */
    {
      title: "Manufacturers",
      to: RouteConstants.MANUFACTURERS,
      htmlBefore: '<i class="material-icons">tag</i>',
      htmlAfter: "",
      access: ['admin','authenticated','superadmin'],
    },
    {
      title: "Distributors",
      to: RouteConstants.DISTRIBUTORS,
      htmlBefore: '<i class="material-icons">label</i>',
      htmlAfter: "",
      access: ['admin','authenticated','superadmin'],
    },
    {
      title: "Companies",
      to: RouteConstants.COMPANIES,
      htmlBefore: '<i class="material-icons">label</i>',
      htmlAfter: "",
      access: ['superadmin'],
    },
    {
      title: "Merchants",
      to: RouteConstants.MERCHANTS,
      htmlBefore: '<i class="material-icons">label</i>',
      htmlAfter: "",
      access: ['admin','authenticated','superadmin'],
    },
    {
      title: "Users",
      to: RouteConstants.USERS,
      htmlBefore: '<i class="material-icons">people</i>',
      htmlAfter: "",
      access: ['admin','superadmin'],
    },
    {
      title: "Spectra Scans",
      to: RouteConstants.SPECTRASCANS,
      htmlBefore: '<i class="material-icons">settings_ethernet</i>',
      htmlAfter: "",
      access: ['superadmin'],
    },
    {
      title: "Calibrations",
      to: RouteConstants.CALIBRATIONS,
      htmlBefore: '<i class="material-icons">compass_calibration</i>',
      htmlAfter: "",
      access: ['admin','authenticated','superadmin'],
    },
    /*{
      title: "Blog Posts",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
    {
      title: "Add New Post",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-post",
    },*/
    /*{
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview",
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables",
    },*/
    /*{
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    {
      title: "Errors",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors",
    }*/
  ];
}
