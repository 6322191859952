import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Progress,
  Button
} from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { Calibration } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';
import { connect, ConnectedProps } from 'react-redux';
import { time } from '../../services/convertTime';

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type ViewCalibrationProps = RouteComponentProps & ConnectedProps<typeof connector>;

class ViewCalibration extends React.Component<ViewCalibrationProps> {

  private calibration: Calibration = this.props.location.state.data;

  componentDidMount() {

  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={'Calibration View'}
            subtitle="Calibration Profile" md="12"
            className="ml-sm-auto mr-sm-auto"
          />
        </Row>
        <Row>
          <Col lg="6" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="12">
                    <h6 className="m-0">Calibration Detail</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <Col lg="12" className="mb-3">
                    <h5 className="title">
                      {this.calibration.type === "sod" ? "Start of Day" : "End of Day"} Calibration
                    </h5>
                    <h6>{time(this.calibration.created_at)}</h6>
                    <h6><strong>Is Calibration Sample:</strong> {this.calibration.is_calibration_sample ? "Yes" : "No"}</h6>
                    {this.props.userData.user.role.type === 'superadmin' && (
                      <h6><strong>User:</strong> {this.calibration.user?.username}</h6>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }

}

export default connector(ViewCalibration);