import React from 'react';
import { BrowserRouter as Router, Route, Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { manufacturerTableColumn } from '../../components/tables/tableColumn';
import { RouteConstants } from '../../constants/RouteConstants';

interface State {
  manufacturers: any[];
  manufacturersCount: number;
  manufacturerDeleted: boolean;
}

interface ComponentProps {
  manufacturers: any | undefined;
}

type UsersProps = RouteComponentProps & ComponentProps;

export default class Manufacturers extends React.Component<UsersProps, State> {

  constructor(props: any) {
    super(props);
    this.getNewPageItem = this.getNewPageItem.bind(this);
  }

  public state: State = {
    manufacturers: [],
    manufacturersCount: 0,
    manufacturerDeleted: false,
  }

  componentDidMount() {
    authApiCall.get(`/manufacturers/count/`)
      .then(response => {
        // console.log(response.data);
        this.setState({ manufacturersCount: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  private getNewPageItem(perPageLimit: number, startAt: number) {
    authApiCall.get(`/manufacturers?_limit=${perPageLimit}&_start=${startAt}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ manufacturers: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  public render(): React.ReactNode {
    const { manufacturers, manufacturersCount } = this.state;
    return (
      <Container flui className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="10">
            <PageTitle title="Manufacturers" subtitle="All manufacturers" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
          <Col lg="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Link style={{ color: '#ffffff' }} to={RouteConstants.ADD_MANUFACTURER}>
              <Button theme="primary" className="mb-0 mr-0">Add New Manufacturer</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Current manufacturers</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  path={RouteConstants.MANUFACTURERS}
                  data={manufacturers}
                  columnsData={manufacturerTableColumn}
                  actionType={String(this.props.history.action)}
                  itemCount={manufacturersCount}
                  onPaginationActionClicked={this.getNewPageItem}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}