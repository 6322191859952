import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  FormGroup,
  FormCheckbox,
  FormSelect,
  Button
} from "shards-react";
import {
  Distributor,
  DistributorFormData,
  DistributorFormState,
} from '../../models';
import { FormValidator, messages } from './FormValidator';
import ShowErrorMessages from './ShowErrorMesssages';
import Noty from 'noty';

interface State extends DistributorFormData { }
interface ComponentProps {
  distributorInfo?: Distributor;
  submitButtonTitle: string;
  onButtonClick: (distributorInfo: State) => void;
}

class AddNewDistributorForm extends React.Component<ComponentProps, State> {

  public state: State = {
    ...DistributorFormState,
  }

  componentDidMount() {
    if (this.props.distributorInfo) {
      const distributorInfo = this.props.distributorInfo;
      this.setState({ ...distributorInfo });
    }
  }

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value } as Pick<State, keyof State>);
  }

  private formSubmission = () => {
    const { name, website, tel, address } = this.state;

    let formData = { ...this.state };
    // console.warn(formData);

    // Need to perform field validation check
    if (name !== '') {
      this.props.onButtonClick(this.state)
    } else {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: 'Distributor name cannot be empty.',
        killer: true,
      }).show();
    }
  }

  public render(): React.ReactNode {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <FormValidator>
                {(formVal: any) => (
                  <Form onSubmit={formVal.handleSubmit(this.formSubmission)}>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="distributor_name">Name</label>
                        <input
                          className={`form-control ${formVal.errors.name ? "is-invalid" : ''}`}
                          id="distributor_name"
                          type="text"
                          name="name"
                          placeholder="Distributor Name"
                          defaultValue={this.state.name ? this.state.name : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="name" />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="website">Website</label>
                        <FormInput
                          id="website"
                          type="text"
                          name="website"
                          placeholder="Website"
                          value={this.state.website ? this.state.website : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="tel">Tel</label>
                        <FormInput
                          id="tel"
                          type="tel"
                          name="tel"
                          placeholder="Telephone"
                          value={this.state.tel ? this.state.tel : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md="6" className="form-group"></Col>
                    </Row>

                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="address">Address</label>
                        <FormTextarea
                          id="address"
                          type="text"
                          name="address"
                          placeholder="Distributor Address"
                          value={this.state.address ? this.state.address : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>

                    <Button type="submit" className="mr-0">{this.props.submitButtonTitle}</Button>
                  </Form>
                )}
              </FormValidator>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
  }
}

export default AddNewDistributorForm;
