import { User } from "./User";
import { Sample } from "./Samples";
import { Scan } from "./Scan";
import { IncludeDateData } from './Include';

export interface Manufacturer extends IncludeDateData {
  id?: number;
  name: string;
  label_name: string;
  website: string;
  tel: string;
  address: string;
  country?: string;
  user?: User;
  samples?: Sample[];
  spectra_data?: Scan;
  status: string;
}

export type ManufacturerFormData = Omit<Manufacturer, 'id'|'country'|'user'|'samples'|'spectra_data'|'updated_at'|'created_at'>;

export const ManufacturerFormState = {
  name: '',
  label_name: '',
  website: '',
  tel: '',
  address: '',
  status: 'unapproved',
};