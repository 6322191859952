import React from 'react';
import { BrowserRouter as Router, Route, Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { companyTableColumn } from '../../components/tables//tableColumn';
import { RouteConstants } from '../../constants/RouteConstants';
import { Company } from '../../models';
import { connect, ConnectedProps } from 'react-redux';

interface State {
  companies: Company[];
  companiesCount: number;
  companyDeleted: boolean;
}

interface ComponentProps {
  companies: Company | undefined;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type CompanyProps = RouteComponentProps & ComponentProps & ConnectedProps<typeof connector>;

class Companies extends React.Component<CompanyProps, State> {

  constructor(props: any) {
    super(props);
    this.getNewPageItem = this.getNewPageItem.bind(this);
  }

  public state: State = {
    companies: [],
    companiesCount: 0,
    companyDeleted: false,
  }

  componentDidMount() {
    // Redirect unauthenticated users
    let userType = this.props.userData.user.role.type;
    if (userType !== "superadmin") {
      this.props.history.push(RouteConstants.ADMIN);
    } else {
      authApiCall.get('/companies/count')
        .then(response => {
          this.setState({ companiesCount: response.data })
        })
        .catch(error => {
          console.warn(error);
        })
    }
  }

  private getNewPageItem(perPageLimit: number, startAt: number) {
    authApiCall.get(`/companies?_limit=${perPageLimit}&_start=${startAt}`)
      .then(response => {
        this.setState({ companies: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  public render(): React.ReactNode {
    const { companies, companiesCount } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="10">
            <PageTitle title="Companies" subtitle="All Company" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
          <Col lg="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Link style={{ color: '#ffffff' }} to={RouteConstants.ADD_COMPANY}>
              <Button theme="primary" className="mb-0 mr-0">Add New Company</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Current Companies</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  path={RouteConstants.COMPANIES}
                  data={companies}
                  columnsData={companyTableColumn}
                  actionType={String(this.props.history.action)}
                  itemCount={companiesCount}
                  onPaginationActionClicked={this.getNewPageItem}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default connector(Companies);