import React from 'react';
import { Link, RouteComponentProps } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { distributorTableColumn } from '../../components/tables/tableColumn';
import { Distributor } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';

interface State {
  distributors: Distributor[];
  distributorsCount: number;
  distributorDeleted: boolean;
}

interface ComponentProps {
  distributors: Distributor | undefined;
}

type UsersProps = RouteComponentProps & ComponentProps;

export default class Distributors extends React.Component<UsersProps, State> {

  constructor(props: any) {
    super(props);
    this.getNewPageItem = this.getNewPageItem.bind(this);
  }

  public state: State = {
    distributors: [],
    distributorsCount: 0,
    distributorDeleted: false,
  }

  componentDidMount() {
    authApiCall.get('/distributors/count')
      .then(response => {
        this.setState({ distributorsCount: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  private getNewPageItem(perPageLimit: number, startAt: number) {
    authApiCall.get(`/distributors?_limit=${perPageLimit}&_start=${startAt}`)
      .then(response => {
        this.setState({ distributors: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  public render(): React.ReactNode {
    const { distributors, distributorsCount } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="10">
            <PageTitle title="Distributors" subtitle="All Distributors" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
          <Col lg="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Link style={{ color: '#ffffff' }} to={RouteConstants.ADD_DISTRIBUTOR}>
              <Button theme="primary" className="mb-0 mr-0">Add New Distributor</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Current Distributors</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  path={RouteConstants.DISTRIBUTORS}
                  data={distributors}
                  columnsData={distributorTableColumn}
                  actionType={String(this.props.history.action)}
                  itemCount={distributorsCount}
                  onPaginationActionClicked={this.getNewPageItem}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}