import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  //FormTextarea,
  //FormGroup,
  FormCheckbox,
  FormSelect,
  Button
} from "shards-react";
import { isEmptyObject } from '../../services/checkProperties';
import Noty from 'noty';
import moment from 'moment';
import {
  Sample,
  SampleFormData,
  SampleFormState,
} from '../../models';

interface State extends SampleFormData { }

interface ComponentProps {
  sampleInfo?: Sample;
  submitButtonTitle: string;
  onButtonClick: (sampleInfo: State) => void;
}

type FormEvents = HTMLInputElement & HTMLSelectElement & moment.Moment;

class AddNewSampleForm extends React.Component<ComponentProps, State> {

  public state: State = {
    ...SampleFormState
  }

  public formOptions = {
    strength: [
      'Weak',
      'Strong'
    ],
    shape: [
      'Two-handle',
      'One-handle'
    ],
    flexibility: [
      'Yes',
      'No'
    ],
    transparency: [
      'Yes',
      'No'
    ]
  }

  componentDidMount() {
    if (this.props.sampleInfo) {
      delete this.props.sampleInfo.collection;
      delete this.props.sampleInfo.manufacturer;
      delete this.props.sampleInfo.distributor;
      delete this.props.sampleInfo.image;
      delete this.props.sampleInfo.scans;
      delete this.props.sampleInfo.created_at;
      delete this.props.sampleInfo.updated_at;
      this.setState({ ...this.props.sampleInfo })
    }
  }

  private handleChange = (event: React.FormEvent<FormEvents>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value } as any);
  }

  private handleFlexibility = () => {
    this.setState(prevState => {
      return {
        flexibility: !prevState.flexibility
      }
    });
  }

  private handleTransparency = () => {
    this.setState(prevState => {
      return {
        transparency: !prevState.transparency
      }
    });
  }

  private formSubmission = (e: Event) => {
    e.preventDefault();

    let formData = { ...this.state };
    // console.warn(formData);

    if (!isEmptyObject(formData)) {
      this.props.onButtonClick(formData)
    } else {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: 'Field cannot be empty.',
        killer: true,
      }).show();
    }
  }

  public render(): React.ReactNode {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            {/*JSON.stringify(this.state)*/}
            <Col>
              <Form>
                <Row form>
                  <Col md="4" className="form-group">
                    <label htmlFor="name">Name</label>
                    <FormInput
                      id="name"
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={this.state.name ? this.state.name : ''}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="4" className="form-group">
                    <label htmlFor="thickness">Thickness</label>
                    <FormInput
                      id="thickness"
                      type="text"
                      name="thickness"
                      placeholder="Thickness"
                      value={this.state.thickness ? this.state.thickness : ''}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="strength">Strength</label>
                    <FormSelect
                      id="strength"
                      type="text"
                      name="strength"
                      placeholder="Strength"
                      defaultValue="Select strength"
                      value={this.state.strength ? this.state.strength : ''}
                      onChange={this.handleChange}
                    >
                      <option value="" disabled key="none">Select strength</option>
                      {
                        this.formOptions.strength.map((value, key) => {
                          return (
                            <option value={value} key={key}>{value}</option>
                          );
                        })
                      }
                    </FormSelect>
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="weight">Weight</label>
                    <FormInput
                      id="weight"
                      type="text"
                      name="weight"
                      placeholder="Weight"
                      value={this.state.weight ? this.state.weight : ''}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col md="4" className="form-group">
                    <label htmlFor="flexibility">Flexibility</label>
                    <FormCheckbox
                      name="flexibility"
                      checked={this.state.flexibility}
                      onChange={this.handleFlexibility}
                      toggle
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="transparency">Transparency</label>
                    <FormCheckbox
                      name="transparency"
                      checked={this.state.transparency}
                      onChange={this.handleTransparency}
                      toggle
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="colour">Colour</label>
                    <FormInput
                      id="colour"
                      type="text"
                      name="colour"
                      placeholder="Colour"
                      value={this.state.colour ? this.state.colour : ''}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col md="4" className="form-group">
                    <label htmlFor="width">Width (cm)</label>
                    <FormInput
                      id="width"
                      type="text"
                      name="width"
                      placeholder="Width"
                      value={this.state.width ? this.state.width : ''}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="height">Height (cm)</label>
                    <FormInput
                      id="height"
                      type="text"
                      name="height"
                      placeholder="Height"
                      value={this.state.height ? this.state.height : ''}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="size">Size (cm<sup>2</sup>)</label>
                    <FormInput
                      id="size"
                      type="text"
                      name="size"
                      placeholder="Size"
                      value={this.state.size ? this.state.size : ''}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="shape">Shape</label>
                    <FormSelect
                      id="shape"
                      type="text"
                      name="shape"
                      placeholder="Shape"
                      defaultValue="Select sample shape"
                      value={this.state.shape ? this.state.shape : ''}
                      onChange={this.handleChange}
                    >
                      <option value="" disabled key="none">Select shape</option>
                      {
                        this.formOptions.shape.map((value, key) => {
                          return (
                            <option value={value} key={key}>{value}</option>
                          );
                        })
                      }
                    </FormSelect>
                  </Col>
                </Row>

                <Button onClick={this.formSubmission} className="mr-0">{this.props.submitButtonTitle}</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
  }
}

export default AddNewSampleForm;
