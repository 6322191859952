import { Sample } from "./Samples";
import { IncludeDateData } from './Include';

export interface Distributor {
  id?: number;
  name: string;
  website: string;
  tel: string;
  address: string;
  status: string;
  last_updated?: Date | string;
  last_synced?: Date | string;
}

export type DistributorFormData = Omit<Distributor, 'id'|'last_updated'|'last_synced'>;

export const DistributorFormState: DistributorFormData = {
  name: '',
  website: '',
  tel: '',
  address: '',
  status: 'unapproved',
};