export const manufacturerTableColumn = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Manufacturer Name',
        accessor: 'name',
      },
      {
        Header: 'Label Name',
        accessor: 'label_name',
      },
      {
        Header: 'Website',
        accessor: 'website',
      },
      {
        id: 'buttonLink',
        Header: '',
      },
    ],
  },
];