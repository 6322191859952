import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button
} from "shards-react";
import {
  Collection,
  CollectionFormData,
  CollectionFormState
} from '../../models';
import DateTime from 'react-datetime';
import { time } from '../../services/convertTime';
import moment from 'moment';
import { FormValidator, messages } from './FormValidator';
import ShowErrorMessages from './ShowErrorMesssages';
import { isEmptyObject } from '../../services/checkProperties';
import Noty from 'noty';

interface State extends CollectionFormData { }

interface ComponentProps {
  collectionInfo?: Collection;
  submitButtonTitle: string;
  onButtonClick: (collectionInfo: State) => void;
}

type FormEvents = HTMLInputElement & HTMLSelectElement & moment.Moment;

class AddNewCollectionForm extends React.Component<ComponentProps, State> {

  public state: State = {
    ...CollectionFormState
  }

  public formOptions = {
    payment_status: [
      'Free',
      'Paid'
    ],
    status: [
      'Pending',
      'Complete'
    ]
  }

  componentWillMount() {
    if (this.props.collectionInfo) {
      const collectionInfo = this.props.collectionInfo;
      delete collectionInfo.merchant;
      delete collectionInfo.collection_name;
      delete collectionInfo.collection_type;
      this.setState({ ...collectionInfo })
    }
  }

  private handleChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value } as Pick<State, keyof State>);
  }

  private setCollectionDate = (value: string | moment.Moment) => {
    let date = time(value, '');
    // console.log(date);
    this.setState({
      collection_date: date,
    });
    this.setCollectionTime(date, true);
  }

  private setCollectionTime = (value: string | moment.Moment, afterSetDate = false) => {
    let time = moment(value);
    let ctime = moment(this.state.collection_time);
    let hour = ctime.hour();
    let minute = ctime.minute();
    let second = ctime.second();
    if (afterSetDate) {
      time.hour(hour).minute(minute).second(second);
    }
    // console.log(time);
    this.setState({ collection_time: time });
  }

  private formSubmission = () => {
    //e.preventDefault();

    let formData = { ...this.state };
    // console.log('Collection formData', formData, this.state);

    // Reasoning
    if (!isEmptyObject(formData)) {
      this.props.onButtonClick(formData)
    } else {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: 'Field cannot be empty.',
        killer: true,
      }).show();
    }
  }

  public render(): React.ReactNode {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <FormValidator>
                {(formVal: any) => (
                  <Form onSubmit={formVal.handleSubmit(this.formSubmission)}>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="collection_id">Collection Name</label>
                        <input
                          className={`form-control ${formVal.errors.collection_id ? "is-invalid" : ''}`}
                          id="collection_id"
                          type="text"
                          name="collection_id"
                          placeholder="Collection Name"
                          defaultValue={this.state.collection_id !== '' ? this.state.collection_id : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="collection_id" />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="collection_date">Collection Date</label>
                        <DateTime
                          value={this.state.collection_date ? moment(this.state.collection_date) : ''}
                          dateFormat={'LL'}
                          timeFormat={false}
                          onChange={(value) => this.setCollectionDate(value)}
                        />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="collection_time">Collection Time</label>
                        <DateTime
                          value={this.state.collection_time ? moment(this.state.collection_time) : ''}
                          dateFormat={false}
                          timeFormat={'hh:mm A'}
                          onChange={(value) => this.setCollectionTime(value)}
                        />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="payment_status">Payment Status</label>
                        <select
                          className={`form-control ${formVal.errors.payment_status ? "is-invalid" : ''}`}
                          id="payment_status"
                          name="payment_status"
                          placeholder="Payment Status"
                          defaultValue={this.state.payment_status ? this.state.payment_status : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        >
                          <option value="" disabled key="none">Select payment status</option>
                          {
                            this.formOptions.payment_status.map((value, key) => {
                              return (
                                <option value={value} key={key}>{value}</option>
                              );
                            })
                          }
                        </select>
                        <ShowErrorMessages errors={formVal.errors} name="payment_status" />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="status">Status</label>
                        <select
                          className={`form-control ${formVal.errors.status ? "is-invalid" : ''}`}
                          id="status"
                          name="status"
                          placeholder="Status"
                          defaultValue={this.state.status ? this.state.status : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        >
                          <option value="" disabled key="none">Select status</option>
                          {
                            this.formOptions.status.map((value, key) => {
                              return (
                                <option value={value} key={key}>{value}</option>
                              );
                            })
                          }
                        </select>
                        <ShowErrorMessages errors={formVal.errors} name="status" />
                      </Col>
                      <Col md="6" className="form-group"></Col>
                    </Row>

                    <Button type="submit" className="mr-0">{this.props.submitButtonTitle}</Button>
                  </Form>
                )}
              </FormValidator>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
  }
}

export default AddNewCollectionForm;
