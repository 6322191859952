export function isEmptyObject(o) {
  let isEmpty = false;
  Object.values(o).forEach((x) => {
    if (x === '' || x === null || x === undefined) {
      isEmpty = true;
    }
  });
  return isEmpty;
}

export function showData(value) {
  if(value) {
    return value;
  } else {
    return 'N/A';
  }
}