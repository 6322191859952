import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from 'shards-react';
//import { time } from '../../services/convertTime';
import PageTitle from '../../components/common/PageTitle';
import { Sample, SampleFormData,SpectraData, Scan } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';
import Noty from 'noty';
import SampleForm from '../../components/custom/SampleForm';
import { authApiCall } from '../../services/AuthApiCallService';

interface State {
  scans: Scan[];
}

class EditSample extends React.Component<RouteComponentProps, State> {

  private sample: Sample = this.props.location.state.data;
  private data: SpectraData = this.props.location.state.data;
  private prevPath = this.props.location.state.prevPath;

  public state: State = {
    scans: [],
  }

  async componentDidMount() {}

  private updateSample = (sample: SampleFormData) => {
    authApiCall.put(`/samples/${this.sample.id}`, {
      ...sample,
    })
    .then(response => {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'success',
        text: 'Sample updated.',
        killer: true,
      }).show();
      //Replace the location state of the current route
      this.props.history.replace(this.props.location.pathname, { data: { ...response.data } });

      //2 Options:
      //1) Manually refresh the browser
      window.location.reload();
      //2) redirect back to main list
      //this.props.history.goBack();
    }).catch(error => {
      console.warn(error);
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: error.message,
        killer: true,
      }).show();
    });
  }

  private deleteSample = (id: any) => {
    let deletion = new Noty({
      theme: 'sunset',
      layout: 'topRight',
      text: 'Do you want to continue?',
      buttons: [
        Noty.button('YES', 'btn btn-success', () => {
          authApiCall.delete(`/samples/${id}`)
            .then(response => {
              deletion.close();
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'warning',
                text: 'Sample Deleted',
                killer: true,
              }).show();
              this.props.history.push('/samples', {sampleDeleted: true});
            }).catch(error => {
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'error',
                text: error.message,
                killer: true,
              }).show();
              deletion.close();
            });
        }, { id: 'button1', 'data-status': 'ok' }),

        Noty.button('NO', 'btn btn-danger', () => {
          deletion.close();
        })
      ]
    });
    deletion.show();
  }

  private renderScans = (scans: Scan[]) => {
    const { prevPath } = this.props.location.state;
    return (
      scans.map((value, key) => {
        return (
          <Link
            style={{ color: '#000000', textDecoration: 'none' }}
            to={{
              pathname: `${RouteConstants.SCANS}/${key}`,
              state: { data: { ...value }, prevPath }
            }}
          >
            <Card small className="mb-2 flex-auto w-25">
              <CardBody className="d-flex align-items-start">
                <div className="pl-3 w-100">
                  <ListGroup flush>
                    <ListGroupItem className="p-0">
                      <span>
                        <strong>Scan {key + 1}</strong>
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </CardBody>
            </Card>
          </Link>
        )
      })
    )
  }

  public render(): React.ReactNode {

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={this.sample.name}
            subtitle="Sample Information" md="12"
            className=""
          />
          <strong className="title-info">{`(ID:${this.sample.id})`}</strong>
        </Row>
        <Row>
          <Col lg="12" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="6">
                    <h6 className="m-0">Sample Detail</h6>
                  </Col>
{/*                   <Col lg="6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => this.deleteSample(this.sample.id)} theme="danger" className="mb-0">
                      Remove Sample
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <SampleForm
                  sampleInfo={this.sample}
                  submitButtonTitle="Update Sample"
                  onButtonClick={this.updateSample}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default EditSample;