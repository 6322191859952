import React from "react";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

import RangeDatePicker from "../../components/common/RangeDatePicker";
import Chart from 'chart.js';
import Charts from "../../utils/chart";

interface ComponentProps {
  title?: String;
  chartData: any;
  chartOptions?: any;
  height?: string;
}

interface State {
  title: String,
  spectralChart: Chart | any;
}

class SpectralPlot extends React.Component<ComponentProps, State> {
  private canvasRef: React.RefObject<HTMLCanvasElement>;

  constructor(props: any) {
    super(props);

    this.canvasRef = React.createRef();
  }

  public state: State = {
    title: "",
    spectralChart: "",
  };

  componentDidMount() {
    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick: any, index: number) {
                  // Jump every 7 values on the X axis labels to avoid clutter.
                  return index % 1 !== 0 ? "" : tick;
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Wavelength (cm^-1)'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 1,
                callback(tick: any) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Absorbance'
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...this.props.chartOptions
    };

    this.setState({
      title: this.props.title ? this.props.title : "",
      spectralChart: new Chart(this.canvasRef.current!, {
        type: "LineWithLine",
        data: this.props.chartData,
        options: chartOptions
      }),
    }, () => {
      // Render the chart.
      this.state.spectralChart.render();
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if(prevProps.chartData !== this.props.chartData) {
      this.state.spectralChart.data = this.props.chartData;
      // console.warn("chartData received", this.props.chartData);
    }
    if(prevProps.chartOptions !== this.props.chartOptions) {
      this.state.spectralChart.options = this.props.chartOptions;
      // console.warn("chartOptions received", this.props.chartOptions);
    }
    this.state.spectralChart.update();
  }

  render() {
    const { title } = this.props;
    return (
      <Card small className="h-100">
        { this.state.title !== "" && (
          <CardHeader className="border-bottom">
            <h6 className="m-0">{this.state.title}</h6>
          </CardHeader>
        )}
        <CardBody className="pt-0">
{/*           <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
              <RangeDatePicker />
            </Col>
            <Col>
              <Button
                size="sm"
                className="d-flex btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0"
              >
                View Full Report &rarr;
              </Button>
            </Col>
          </Row> */}
          <canvas
            height={this.props.height ? this.props.height : "180"}
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </CardBody>
      </Card>
    );
  }
}

export default SpectralPlot;
