export const spectrascanTableColumn = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        id: 'createdAt',
        Header: 'Created At',
        accessor: 'created_at',
      },
      {
        id: 'buttonLink',
        Header: '',
      },
    ],
  },
];