export const collectionTableColumn = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Collection Name',
        accessor: 'collection_id',
      },
      {
        Header: 'No. of Samples',
        accessor: 'samples.length',
        /*Cell: ({row}: any) => {
          const length = row.original.samples.length;
          return length > 0 ? length : '-';
        }*/
      },
      {
        id: 'collectionDate',
        Header: 'Collection Date',
        accessor: 'collection_date',
      },
      {
        id: 'collectionTime',
        Header: 'Collection Time',
        accessor: 'collection_time',
      },
      {
        id: 'whichUser',
        Header: 'User',
        accessor: 'user.username',
      },
      {
        id: 'buttonLink',
        Header: '',
      },
    ],
  },
];