import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { connect } from 'react-redux';

import { version } from '../../../../package.json';

const mapState = (state) => (
  { userData: state.userData }
);

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            item.access.includes(Object.entries(this.props.userData).length > 0 ? this.props.userData.user.role.type : '') ?
              <SidebarNavItem key={idx} item={item} />
            : !item.access.includes(Object.entries(this.props.userData).length > 0 ? this.props.userData.user.role.type : '') ? 
              null
            : <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>
        <span class="app-version" >Version: {version}</span>
      </div>
    )
  }
}

export default connect(
  mapState
)(SidebarNavItems);
