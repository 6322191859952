import { User } from './User';
import { IncludeDateData } from './Include';
 
export interface Company extends IncludeDateData {
  id?: string;
  company_name: string;
  country?: string;
  user?: User;
  status: string;
}

export type CompanyFormData = Omit<Company, 'id'|'country'|'user'|'updated_at'|'created_at'>;

export const CompanyFormState = {
  company_name: '',
  status: 'unapproved',
};