import React, { createRef } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import {
  Merchant,
  MerchantFormData,
  MerchantFormState,
} from '../../models';
import { isEmptyObject } from '../../services/checkProperties';
import { Map, TileLayer, Marker, Popup, LatLng } from 'react-leaflet';
import { FormValidator, messages } from './FormValidator';
import ShowErrorMessages from './ShowErrorMesssages';
import Noty from 'noty';
import L from "leaflet";

type Position = { lat: any, lng: any };

interface State extends MerchantFormData {
  center?: Position,
  marker?: Position,
  zoom?: number,
  draggable?: boolean,
}

type FormState = Omit<State, 'center' | 'marker' | 'zoom' | 'draggable'>;

interface ComponentProps {
  merchantInfo?: Merchant;
  submitButtonTitle: string;
  onButtonClick: (merchantInfo: FormState) => void;
}

class AddNewMerchantForm extends React.Component<ComponentProps, State> {

  private refmap = createRef<any>();
  private refmarker = createRef<any>();

  public state: State = {
    ...MerchantFormState,
    center: {
      lat: 3.0006,
      lng: 101.5335,
    },
    marker: {
      lat: 3.0006,
      lng: 101.5335,
    },
    zoom: 15,
    draggable: true,
  }

  componentDidMount() {
    if (this.props.merchantInfo) {
      const merchantInfo = this.props.merchantInfo;
      this.setState({
        ...merchantInfo,
        center: {
          lat: merchantInfo.gps_lat,
          lng: merchantInfo.gps_lng,
        },
        marker: {
          lat: merchantInfo.gps_lat,
          lng: merchantInfo.gps_lng,
        }
      });
    }
  }

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value } as Pick<any, keyof any>);
  }

  private handleCoordinatesChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const map = this.refmap.current;
    const newMarker = this.state.marker;
    if (name === 'gps_lat') {
      newMarker!.lat = Number(value);
      this.setState({
        gps_lat: Number(value),
      }, () => {
        this.setState({
          marker: newMarker,
        });
        map.leafletElement.panTo(new L.LatLng(Number(value), Number(this.state.marker!.lng)));
      })
    } else if (name === 'gps_lng') {
      newMarker!.lng = Number(value);
      this.setState({
        gps_lng: Number(value),
      }, () => {
        this.setState({
          marker: newMarker,
        });
        map.leafletElement.panTo(new L.LatLng(Number(this.state.marker!.lat), Number(value)));
      })
    }
  }

  private formSubmission = () => {
    let {
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_postcode,
      address_country,
      gps_coordinates,
      status,
      ...rest
    } = this.state;
    let formData = rest;
    // console.warn(formData);

    const { name } = this.state;

    //if (!isEmptyObject(formData)) {
    if (name !== '') {
      this.props.onButtonClick(this.state);
    } else {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: 'Field cannot be empty.',
        killer: true,
      }).show();
    }
  }

  private toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable })
  }

  private updatePosition = () => {
    const map = this.refmap.current;
    const marker = this.refmarker.current;
    if (marker != null) {
      // console.warn('dragged', marker.leafletElement.getLatLng());
      const {lat, lng} = marker.leafletElement.getLatLng();
      this.setState({
        marker: marker.leafletElement.getLatLng(),
        gps_lat: lat,
        gps_lng: lng,
      }, () => {
        map.leafletElement.panTo(new L.LatLng(lat, lng));
      });
    }
  }

  public render(): React.ReactNode {
    let position = { lat: this.state.center!.lat, lng: this.state.center!.lng };
    let markerPosition = { lat: this.state.marker!.lat, lng: this.state.marker!.lng };

    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <FormValidator>
                {(formVal: any) => (
                  <Form onSubmit={formVal.handleSubmit(this.formSubmission)}>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="name">Merchant Name</label>
                        <input
                          className={`form-control ${formVal.errors.name ? "is-invalid" : ''}`}
                          id="name"
                          type="text"
                          name="name"
                          placeholder="Merchant Name"
                          defaultValue={this.state.name ? this.state.name : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="name" />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="ssm_registration">SSM Registration</label>
                        <input
                          className={`form-control ${formVal.errors.ssm_registration ? "is-invalid" : ''}`}
                          id="ssm_registration"
                          type="text"
                          name="ssm_registration"
                          placeholder="SSM Registration"
                          defaultValue={this.state.ssm_registration ? this.state.ssm_registration : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="ssm_registration" />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="business_category">Business Category</label>
                        <input
                          className={`form-control ${formVal.errors.business_category ? "is-invalid" : ''}`}
                          id="business_category"
                          type="text"
                          name="business_category"
                          placeholder="Business Category"
                          defaultValue={this.state.business_category ? this.state.business_category : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="business_category" />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="business_size">Business Size</label>
                        <input
                          className={`form-control ${formVal.errors.business_size ? "is-invalid" : ''}`}
                          id="business_size"
                          type="text"
                          name="business_size"
                          placeholder="Business Size"
                          defaultValue={this.state.business_size ? this.state.business_size : ''}
                          onChange={this.handleChange}
                          ref={formVal.register({ required: messages.required })}
                        />
                        <ShowErrorMessages errors={formVal.errors} name="business_category" />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="address_line1">Address Line 1</label>
                        <FormInput
                          id="address_line1"
                          type="text"
                          name="address_line1"
                          placeholder="Address Line 1"
                          value={this.state.address_line1 ? this.state.address_line1 : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="address_line2">Address Line 2</label>
                        <FormInput
                          id="address_line2"
                          type="text"
                          name="address_line2"
                          placeholder="Address Line 2"
                          value={this.state.address_line2 ? this.state.address_line2 : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="4" className="form-group">
                        <label htmlFor="address_city">City</label>
                        <FormInput
                          id="address_city"
                          type="text"
                          name="address_city"
                          placeholder="City"
                          value={this.state.address_city ? this.state.address_city : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="address_state">State</label>
                        <FormInput
                          id="address_state"
                          type="text"
                          name="address_state"
                          placeholder="State"
                          value={this.state.address_state ? this.state.address_state : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="address_postcode">Postcode</label>
                        <FormInput
                          id="address_postcode"
                          type="text"
                          name="address_postcode"
                          placeholder="Postcode"
                          value={this.state.address_postcode ? this.state.address_postcode : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="address_country">Country</label>
                        <FormInput
                          id="address_country"
                          type="text"
                          name="address_country"
                          placeholder="Country"
                          value={this.state.address_country ? this.state.address_country : ''}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="gps_coordinates">GPS Coordinates</label>
                        <div className="d-flex justify-content-between">
                          <InputGroup className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>Lat</InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              id="gps_lat"
                              type="number"
                              name="gps_lat"
                              placeholder="Latitude"
                              value={this.state.gps_lat ? this.state.gps_lat : ''}
                              onChange={this.handleCoordinatesChange}
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>Lng</InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              id="gps_lng"
                              type="number"
                              name="gps_lng"
                              placeholder="Longitude"
                              value={this.state.gps_lng ? this.state.gps_lng : ''}
                              onChange={this.handleCoordinatesChange}
                            />
                          </InputGroup>
                        </div>
                        <Map ref={this.refmap} center={[position.lat, position.lng]} zoom={this.state.zoom}>
                          <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker
                            draggable={this.state.draggable}
                            ondragend={this.updatePosition}
                            position={[markerPosition.lat, markerPosition.lng]}
                            ref={this.refmarker}>
                            <Popup minWidth={90}>
                              <span onClick={this.toggleDraggable}>
                                {this.state.name ? this.state.name : 'DRAG MARKER'}
                              </span>
                            </Popup>
                          </Marker>
                        </Map>
                      </Col>
                    </Row>

                    <Button type="submit" className="mr-0">{this.props.submitButtonTitle}</Button>
                  </Form>
                )}
              </FormValidator>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    )
  }
}

export default AddNewMerchantForm;
