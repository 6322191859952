import axios from 'axios';
import store from '../store';

let authToken = '';
export function setToken(token) {
  authToken = token;
}

let options = {
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
};

// console.log("axios options", options);

export const authApiCall = axios.create(options);

// Intercept and add header data when page is refreshed
authApiCall.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = store.getState().userData;
  let userData = token.jwt;
  config.headers.Authorization = 'Bearer ' + userData;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
