import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Progress,
  Button
} from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { Spectrascan } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';
import { connect, ConnectedProps } from 'react-redux';
import SpectralPlot from '../Scans/SpectralPlot';
import { time } from '../../services/convertTime';

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type ViewSpectrascanProps = RouteComponentProps & ConnectedProps<typeof connector>;

class ViewSpectrascan extends React.Component<ViewSpectrascanProps> {

  private spectrascan: Spectrascan = this.props.location.state.data;

  componentDidMount() {
    // Redirect unauthenticated users
    let userType = this.props.userData.user.role.type;
    if (userType !== "superadmin") {
      this.props.history.push(RouteConstants.ADMIN);
    }
  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={'Spectrascan View'}
            subtitle="Spectrascan Profile" md="12"
            className="ml-sm-auto mr-sm-auto"
          />
        </Row>
        <Row>
          <Col lg="4" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="12">
                    <h6 className="m-0">Spectrascan Detail</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-3">
                <Row>
                  <Col lg="12" className="mb-3">
                    <h5>{this.spectrascan.name}</h5>
                    <h6>{time(this.spectrascan.created_at)}</h6>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h5 className="m-0">Spectrum Plot</h5>
              </CardHeader>
              <CardBody>
                {this.spectrascan && (
                  <SpectralPlot
                    chartOptions={{
                      legend: {
                        display: false
                      },
                    }}
                    chartData={{
                      labels: this.spectrascan.data_json['wave_num'],
                      datasets: [
                        {
                          label: "Spectrum",
                          fill: "start",
                          data: this.spectrascan.data_json["spectra"],
                          backgroundColor: "transparent",
                          borderColor: "rgba(0,123,255,1)",
                          pointBackgroundColor: "#ffffff",
                          pointHoverBackgroundColor: "rgb(0,123,255)",
                          borderWidth: 1.5,
                          pointRadius: 0,
                          pointHoverRadius: 3
                        },
                      ]
                    }} />)
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }

}

export default connector(ViewSpectrascan);