import { useForm } from 'react-hook-form';

export function FormValidator({listen, children}: any) {

  let formVal = useForm({
    validateCriteriaMode: "all"
  });

  return children(formVal);

}

export const messages = {
  required: "This field is required",
  empty: "Field cannot be empty",
  email: "Please enter a valid email address",
}

export const regexes =  {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}