import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { apiCall } from '../../services/apiCallService';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { userTableColumn } from '../../components/tables/tableColumn';
import { User } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';
import { connect, ConnectedProps } from 'react-redux';

interface State {
  users: User[];
  usersCount: number;
  userDeleted: boolean;
}

interface ComponentProps {
  users: any | undefined;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type UsersProps = RouteComponentProps & ComponentProps & ConnectedProps<typeof connector>;

class Users extends React.Component<UsersProps, State> {

  constructor(props: any) {
    super(props);
    this.getNewPageItem = this.getNewPageItem.bind(this);
  }

  public state: State = {
    users: [],
    usersCount: 0,
    userDeleted: false,
  }

  componentDidMount() {
    // Redirect unauthenticated users
    let userType = this.props.userData.user.role.type;
    if (userType === "authenticated") {
      this.props.history.push(RouteConstants.ADMIN);
    } else {
      authApiCall.get(`/users/count`)
        .then(response => {
          // console.log(response.data);
          this.setState({ usersCount: response.data })
        })
        .catch(error => {
          console.warn(error);
        });
    }
  }

  private getNewPageItem(perPageLimit: number, startAt: number) {
    authApiCall.get(`/users?_limit=${perPageLimit}&_start=${startAt}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ users: response.data })
      })
      .catch(error => {
        console.warn(error);
      });
  }

  public render(): React.ReactNode {

    const { users, usersCount } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="10">
            <PageTitle title="Users" subtitle="All users" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
          {this.props.userData.user.role.type !== 'authenticated' &&
            (
              <Col lg="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Link style={{ color: '#ffffff' }} to={RouteConstants.ADD_USER}>
                  <Button theme="primary" className="mb-0 mr-0">Add New User</Button>
                </Link>
              </Col>
            )
          }
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Current Users</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  path={RouteConstants.USERS}
                  data={users}
                  columnsData={userTableColumn}
                  actionType={String(this.props.history.action)}
                  itemCount={usersCount}
                  onPaginationActionClicked={this.getNewPageItem}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default connector(Users);
