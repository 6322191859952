export const calibrationTableColumn = [
  {
    id: 'MainHeader',
    Header: 'Main',
    columns: [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Is Calibration Sample',
        accessor: 'is_calibration_sample',
        Cell: ({row}: any) => {
          return row.original.is_calibration_sample ? 'Yes' : 'No';
        }
      },
      {
        id: 'whichUser',
        Header: 'User',
        accessor: 'user.username',
      },
      {
        id: 'createdAt',
        Header: 'Created At',
        accessor: 'created_at',
      },
      {
        id: 'buttonLink',
        Header: '',
      },
    ],
  },
];