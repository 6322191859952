import { createStore, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

const initialState = {
  userData: {},
  pageIndex: 0,
  pageSize: 50,
};

function rootReducer(state = initialState, action: any) {
  switch (action.type) {
    case "SAVE_ID":
      return Object.assign({}, state, {
        userData: action.item
      });

    case "CURRENT_PAGINATION":
      return Object.assign({}, state, {
        pageIndex: action.data.pageIndex,
        pageSize: action.data.pageSize,
      });

    case "RESET_PAGE_INDEX":
      return Object.assign({}, state, {
        pageIndex: 0,
        pageSize: 50,
      });
    
    case "LOGOUT_USER":
      // console.warn("----- USER LOGOUT REDUCER");
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(thunk),
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
