import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import UsersOverview from "../components/blog/UsersOverview";
import UsersByDevice from "../components/blog/UsersByDevice";
import NewDraft from "../components/blog/NewDraft";
import Discussions from "../components/blog/Discussions";
import TopReferrals from "../components/common/TopReferrals";import { authApiCall } from "../services/AuthApiCallService";
import { connect, ConnectedProps } from "react-redux";
import { saveUserID } from '../store/actions/userDataAction';

interface State {
  collections: number;
  samples: number;
  scans: number;
  smallStats: any[];
}

const actionCreators = {
  saveUserID
};

const connector = connect(
  null,
  actionCreators
);

type DashboardProps = ConnectedProps<typeof connector>;

class Dashboard extends React.Component<DashboardProps, State> {

  public state: State = {
    collections: 0,
    samples: 0,
    scans: 0,
    smallStats: []
  }

  async componentDidMount() {
    let graphData = {
      ...this.state
    }
    await authApiCall.get('/collections/count')
    .then(response => {
      // console.log('--- count', response.data);
      graphData.collections = response.data;
    }).catch(error => {
      console.log('---- collection count error', error.message);
    })

    await authApiCall.get('/samples/count')
    .then(response => {
      // console.log('--- count', response.data);
      graphData.samples = response.data;
    }).catch(error => {
      console.log('---- sample count error', error.message);
    })

    await authApiCall.get('/scans/count')
    .then(response => {
      // console.log('--- count', response.data);
      graphData.scans = response.data;
    }).catch(error => {
      console.log('---- scans count error', error.message);
    })

    this.setState({
      smallStats: [
        {
          label: "Collections",
          value: `${graphData.collections}`,
          chartLabels: [null, null],
          attrs: { md: "6", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(0, 184, 216, 0.1)",
              borderColor: "rgb(0, 184, 216)",
              data: [0, graphData.collections]
            }
          ]
        },
        {
          label: "Samples",
          value: `${graphData.samples}`,
          chartLabels: [null, null],
          attrs: { md: "6", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(23,198,113,0.1)",
              borderColor: "rgb(23,198,113)",
              data: [0, graphData.samples]
            }
          ]
        },
        {
          label: "Scans",
          value: `${graphData.scans}`,
          chartLabels: [null],
          attrs: { md: "4", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(255,180,0,0.1)",
              borderColor: "rgb(255,180,0)",
              data: [0, graphData.scans]
            }
          ]
        },
      ]
    })
  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle title="Overview" subtitle="Dashboard" className="text-sm-left mb-3" />
        </Row>
        {/* Small Stats Blocks */}
        <Row>
          {this.state.smallStats.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }

}

export default connector(Dashboard);
