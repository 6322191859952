const RouteProperty = {
  ID: '/:id',
  NAME: '/:name',
  VIEW: '/view',
  ADD: '/add',
  EDIT: '/edit',
}

export const RouteConstants = {

  //Auth Routes
  LOGIN: '/idir-login',
  
  // Admin Routes
  ADMIN: '/admin',

  // User Routes
  USERS: '/users',
  get GET_USER() {
    return this.USERS + RouteProperty.ID
  },
  get ADD_USER() {
    return this.USERS + RouteProperty.ADD
  },

  // Collection Routes
  COLLECTIONS: '/collections',
  get GET_COLLECTION() {
    return this.COLLECTIONS + RouteProperty.ID
  },
  get ADD_COLLECTION() {
    return this.COLLECTIONS + RouteProperty.ADD
  },

  // Sample Routes
  SAMPLES: '/samples',
  get GET_SAMPLE() {
    return this.SAMPLES + RouteProperty.ID
  },
  get EDIT_SAMPLE() {
    return this.SAMPLES + RouteProperty.EDIT + RouteProperty.NAME
  },

  // Scan Routes
  SCANS: '/scans',
  get GET_SCAN() {
    return this.SCANS + RouteProperty.ID
  },

  // Manufacturer Routes
  MANUFACTURERS: '/manufacturers',
  get GET_MANUFACTURER() {
    return this.MANUFACTURERS + RouteProperty.ID
  },
  get ADD_MANUFACTURER() {
    return this.MANUFACTURERS + RouteProperty.ADD
  },

  // Distributor Routes
  DISTRIBUTORS: '/distributors',
  get GET_DISTRIBUTOR() {
    return this.DISTRIBUTORS + RouteProperty.ID
  },
  get ADD_DISTRIBUTOR() {
    return this.DISTRIBUTORS + RouteProperty.ADD
  },

  // Company Routes
  COMPANIES: '/companies',
  get GET_COMPANY() {
    return this.COMPANIES + RouteProperty.ID
  },
  get ADD_COMPANY() {
    return this.COMPANIES + RouteProperty.ADD
  },

  // Merchant Routes
  MERCHANTS: '/merchants',
  get GET_MERCHANT() {
    return this.MERCHANTS + RouteProperty.ID
  },
  get ADD_MERCHANT() {
    return this.MERCHANTS + RouteProperty.ADD
  },

  // Spectra Scan Routes
  SPECTRASCANS: '/spectrascans',
  get GET_SPECTRASCAN() {
    return this.SPECTRASCANS + RouteProperty.ID
  },

  // Merchant Routes
  CALIBRATIONS: '/calibrations',
  get GET_CALIBRATION() {
    return this.CALIBRATIONS + RouteProperty.ID
  },

};