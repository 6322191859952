import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from 'shards-react';
import { time } from '../../services/convertTime';
import PageTitle from '../../components/common/PageTitle';
import { Sample, SpectraData, Scan } from '../../models';
import { RouteConstants } from '../../constants/RouteConstants';
import { authApiCall } from '../../services/AuthApiCallService';

interface State {
  scans: Scan[];
}

class ViewSample extends React.Component<RouteComponentProps, State> {

  private sample: Sample = this.props.location.state.data;
  private data: SpectraData = this.props.location.state.data;
  private prevPath = this.props.location.state.prevPath;

  public state: State = {
    scans: [],
  }

  async componentDidMount() {
    await this.data.spectradata.forEach((value, key) => {
      authApiCall.get(`/spectradata/${value.id}`)
        .then(response => {
          this.setState((state) => {
            return { scans: [...state.scans, response.data] };
          });
        })
        .catch(error => {
          console.warn(error);
        })
    });
  }

  private renderScans = (scans: Scan[]) => {
    const { prevPath } = this.props.location.state;
    return (
      scans.map((value, key) => {
        return (
          <Link
            style={{ color: '#000000', textDecoration: 'none', flexBasis: '48%' }}
            to={{
              pathname: `${RouteConstants.SCANS}/${key}`,
              state: { data: { ...value }, prevPath }
            }}
          >
            <Card small>
              <CardBody className="d-flex align-items-start">
                <div>
                  <span>
                    <strong>Scan {key + 1}</strong>
                  </span>
                </div>
              </CardBody>
            </Card>
          </Link>
        )
      })
    )
  }

  public render(): React.ReactNode {

    const { scans } = this.state;
    const { prevPath } = this.props.location.state;
    const sampleName = this.sample.name.toLowerCase().replace(' ', '_');

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={this.sample.name + ` (ID:${this.sample.id}, Collection: ${this.sample.collection.collection_id})`}
            subtitle="Sample Information" md="12"
            className=""
          />
        </Row>
        <Row>
          <Col lg="8">
            <Card small className="mb-4">
              <CardHeader className="border-bottom d-flex">
                <h6 className="m-0 flex-grow-1">Sample Details</h6>
                <div className="d-flex align-items-center justify-content-end">
                  <Link
                    style={{ color: '#ffffff' }}
                    to={{
                      pathname: `${RouteConstants.SAMPLES}/edit/${sampleName}`,
                        state: { data: { ...this.sample }, prevPath }
                      }}
                  >
                    <Button theme="primary" className="mb-2 mr-1">Edit</Button>
                  </Link>
                </div>
              </CardHeader>
              <CardBody>
                <ListGroup flush>
                  <ListGroupItem className="p-0">
                    <span className="d-flex justify-content-between mt-3">
                      <span><strong className="mr-1">Thickness:</strong> {this.sample.thickness}</span>
                      <span><strong className="mr-1">Strength:</strong> {this.sample.strength}</span>
                      <span><strong className="mr-1">Weight:</strong> {this.sample.weight}</span>
                    </span>
                    <span className="d-flex justify-content-between mt-3">
                      <span><strong className="mr-1">Flexibility:</strong> {this.sample.flexibility ? 'Yes' : 'No'}</span>
                      <span><strong className="mr-1">Transparency:</strong> {this.sample.transparency ? 'Yes' : 'No'}</span>
                      <span><strong className="mr-1">Colour:</strong> {this.sample.colour}</span>
                    </span>
                    <span className="d-flex justify-content-between mt-3">
                      <span><strong className="mr-1">Width:</strong> {this.sample.width} cm</span>
                      <span><strong className="mr-1">Height:</strong> {this.sample.height} cm</span>
                      <span><strong className="mr-1">Size:</strong> {this.sample.size} cm<sup>2</sup></span>
                    </span>
                    <span className="d-flex mt-3">
                      <strong className="mr-1">Shape:</strong> {this.sample.shape.replace("_", " ")}
                    </span>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Scans</h6>
              </CardHeader>
              <CardBody className="d-flex flex-wrap justify-content-around">
                {
                scans.length > 0 ?
                  this.renderScans(scans) :
                  <span>There are currently no scans.</span>
                }
              </CardBody>
            </Card>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Manufacturer</h6>
                  </CardHeader>
                  <CardBody>
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">store</i>
                          <strong className="mr-1">Name:</strong> {this.sample.manufacturer.name}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">label</i>
                          <strong className="mr-1">Label Name:</strong> {this.sample.manufacturer.label_name}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">language</i>
                          <strong className="mr-1">Website:</strong>
                          <a href={this.sample.manufacturer.website}>{this.sample.manufacturer.website}</a>
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">phone</i>
                          <strong className="mr-1">Tel:</strong> {this.sample.manufacturer.tel}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">book</i>
                          <strong className="mr-1">Address:</strong> {this.sample.manufacturer.address}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Distributor</h6>
                  </CardHeader>
                  <CardBody>
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">store</i>
                          <strong className="mr-1">Name:</strong> {this.sample.distributor.name}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">language</i>
                          <strong className="mr-1">Website:</strong>
                          <a href={this.sample.distributor.website}>{this.sample.distributor.website}</a>
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">phone</i>
                          <strong className="mr-1">Tel:</strong> {this.sample.distributor.tel}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">book</i>
                          <strong className="mr-1">Address:</strong> {this.sample.distributor.address}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Others</h6>
                  </CardHeader>
                  <CardBody>
                    <ListGroup flush>
                      <ListGroupItem className="p-0">
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">done_outline</i>
                          <strong className="mr-1">Sirim Label:</strong> {this.sample.sirim_label}
                        </span>
                        <span className="d-flex mb-2">
                          <i className="material-icons mr-1">eco</i>
                          <strong className="mr-1">Eco Label License:</strong> {this.sample.eco_label_license}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Image</h6>
              </CardHeader>
              <CardBody>
              {this.sample.image ?
                <div>
                  <img src={`${process.env.REACT_APP_BASE_URL}${this.sample.image.url}`} alt="" width="100%" />
                  <ListGroup flush>
                    <ListGroupItem className="p-0">
                      <span className="d-flex mt-3">
                        <strong className="mr-1">Name:</strong> {this.sample.image.name}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </div>
                :
                <span>No image.</span>
              }
              </CardBody>
            </Card>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Additional Information</h6>
              </CardHeader>
              <CardBody>
                <ListGroup flush>
                  <ListGroupItem className="p-0">
                    <span className="d-flex mb-2">
                      <i className="material-icons mr-1">bookmark</i>
                      <strong className="mr-1">Purchase Date:</strong> {time(this.sample.purchase_date)}
                    </span>
                    <span className="d-flex mb-2">
                      <i className="material-icons mr-1">bookmark</i>
                      <strong className="mr-1">Intended Use:</strong> {this.sample.intended_use.replace("_", " ")}
                    </span>
                    <span className="d-flex mb-2">
                      <i className="material-icons mr-1">bookmark</i>
                      <strong className="mr-1">Quantity:</strong> {this.sample.quantity}
                    </span>
                    <span className="d-flex mb-2">
                      <i className="material-icons mr-1">access_time</i>
                      <strong className="mr-1">Created At:</strong> {time(this.sample.sample_created_at)}
                    </span>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default ViewSample;