import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormInput
} from 'shards-react';
import {
  useTable,
  //useGroupBy,
  useSortBy,
  //useFilters,
  usePagination,
  useRowSelect,
  UseRowSelectRowProps,
  UseRowSelectInstanceProps,
  useRowState,
  //UseSortByColumnProps,
  //UseSortByColumnOptions,
  //UseSortByOptions,
  //UseSortByHooks,
  Row,
} from 'react-table';
import { time } from '../../services/convertTime';
import store from '../../store';

// Components
import { Pagination } from '../pagination/Pagination';

interface TableProps {
  path?: string;
  onButtonClicked?: (value: any) => any;
  onCheckboxClicked?: (ids: any, value: any) => any;
  onCheckboxAllClicked?: (value: any) => any;
  onPaginationActionClicked: (pageLimit: number, start: number) => void;
  data: any;
  itemCount: number;
  columnsData: any;
}

type MainTableProps = TableProps & UseRowSelectRowProps<any>;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

export const InnerTable: FunctionComponent<TableProps> = ({
  path,
  onButtonClicked,
  onCheckboxClicked,
  onCheckboxAllClicked,
  onPaginationActionClicked,
  data,
  itemCount,
  columnsData,
}) => {

  const [dropdown, setDropdown] = useState(false);
  const columns = useMemo(() => columnsData, [columnsData]);
  const location = useLocation();
  const dataStore: any = store.getState();

  const pageSizeInit: number = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  // console.warn('dataStore', dataStore);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //rows,
    prepareRow,
    pageOptions,
    page,
    //pageCount,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState,
  );

  useEffect(() => {
    onPaginationActionClicked(pageSizeInit, 0);
  }, [pageSizeInit])

  const onPageChange = (page: any, startAt: any) => {
    setCurrentPage(page);
    onPaginationActionClicked(pageSize, startAt)
  }

  function passSelectedRow(index: number, rowData: object) {
    // console.log("selected Row", rowData);
    onCheckboxClicked!(index, rowData);
  }

  function passAllRows(rowData: any[]) {
    let allRows: any[] = rowData.map((item: any) => item?.original);
    // console.log("all Rows", allRows);
    onCheckboxAllClicked!(allRows);
  }

  return (
    <div>
      {/*       {<pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </code>
      </pre>} */}
      <div className="p-2 table-actions">
        {/* <div style={style.halignCenter}>
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            outline
            theme="primary"
            className="mb-2 mr-1"
          >
            Previous Page
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            outline
            theme="primary"
            className="mb-2 mr-1"
          >
            Next Page
          </Button>
        </div> */}
        <div style={style.halignRight}>
          {/* <div>Go to page:</div>
          <FormInput
            style={{ width: '80px' }}
            type="number"
            min="1"
            defaultValue={pageIndex + 1 || 1}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          /> */}
          <Dropdown caret open={dropdown} toggle={() => setDropdown(!dropdown)}>
            <DropdownToggle outline caret>Show {pageSize}</DropdownToggle>
            <DropdownMenu right small>
              {pageSizeOptions.map(pageSize => (
                <DropdownItem
                  key={pageSize}
                  value={pageSize}
                  onClick={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  Show {pageSize}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <table {...getTableProps()} className="table mb-0">
        <thead className="bg-light">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                if (column.id === 'checkbox') {
                  return (
                    <th>
                      <div>
                        <IndeterminateCheckbox
                          {...getToggleAllRowsSelectedProps()}
                          onClick={() => passAllRows(page)}
                        />
                      </div>
                    </th>
                  )
                } else if (column.id === 'whichUser') {
                  if (dataStore.userData.user.role.type === 'superadmin') {
                    return (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={column.Header === 'Main' ? style.displayNone : undefined}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                      </th>
                    )
                  } else {
                    return;
                  }
                } else {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={column.Header === 'Main' ? style.displayNone : undefined}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  )
                }
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    if (cell.column.id === 'checkbox') {
                      return (
                        <td>
                          <div>
                            <IndeterminateCheckbox
                              {...row.getToggleRowSelectedProps()}
                              onClick={() => passSelectedRow(row.index, row.original)}
                            />
                          </div>
                        </td>
                      )
                    } else if (cell.column.id === 'buttonLink') {
                      return (
                        <td key={`link-${row.index}`}>
                          <Link
                            style={{ color: '#ffffff' }}
                            to={{
                              pathname: `${path}/${row.index}`,
                              state: {
                                data: data[row.index],
                                prevPath: location.pathname,
                              }
                            }}
                          >
                            <Button theme="primary" className="mb-2 mr-1 table-button">View</Button>
                          </Link>
                        </td>
                      )
                    } else if (cell.column.id === 'buttonClick') {
                      return (
                        <td>
                          <Button
                            theme="primary"
                            className="mb-2 mr-1 table-button"
                            onClick={() => onButtonClicked!(data[row.index])}
                          >
                            View
                          </Button>
                        </td>
                      )
                    } else if (
                      cell.column.id === 'createdAt' ||
                      cell.column.id === 'collectionDate' ||
                      cell.column.id === 'collectionTime') {
                      return <td {...cell.getCellProps()}>{time(cell.value, 'DD/MM/YYYY, h:mm:ss a')}</td>
                    } else if (cell.column.id === 'image') {
                      return (
                        <td>
                          {data[row.index].image ?
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${data[row.index].image!.url}`}
                              alt={data[row.index].image.name}
                              style={{ width: '75px' }}
                            /> :
                            <div style={{ width: '75px', height: '50px', backgroundColor: '#eee' }}></div>
                          }
                        </td>
                      )
                    } else if (cell.column.id === 'whichUser') {
                      if (dataStore.userData.user.role.type === 'superadmin') {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      } else {
                        return;
                      }
                    } else {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    }
                  })}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
      <div className="p-2">
        <Pagination
          itemCount={itemCount}
          perPageLimit={pageSize}
          paginationCurrentPage={currentPage}
          onPrevPage={(page, startAt) => onPageChange(page, startAt)}
          onNextPage={(page, startAt) => onPageChange(page, startAt)}
          onPageNumberClick={(page, startAt) => onPageChange(page, startAt)}
        />
      </div>
      <div className="p-2">
        <div style={style.halignRight}>
          <div>
            Page{' '}
            <em>
              {itemCount && (
                <em>
                  {currentPage} of {Math.ceil(itemCount / pageSize)}
                </em>
              )}
            </em>
          </div>
        </div>
      </div>
    </div>
  )
};

const pageSizeOptions: number[] = [
  10, 15, 20
];

const style = {
  displayNone: {
    display: 'none',
  },
  halignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  halignRight: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
};