import React from 'react';
import { BrowserRouter as Router, Route, Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { merchantTableColumn } from '../../components/tables/tableColumn';
import { RouteConstants } from '../../constants/RouteConstants';
import { Merchant } from '../../models';

interface State {
  merchants: Merchant[];
  merchantsCount: number;
  merchantDeleted: boolean;
}

interface ComponentProps {
  merchants: Merchant | undefined;
}

type UsersProps = RouteComponentProps & ComponentProps;

export default class Merchants extends React.Component<UsersProps, State> {

  constructor(props: any) {
    super(props);
    this.getNewPageItem = this.getNewPageItem.bind(this);
  }

  public state: State = {
    merchants: [],
    merchantsCount: 0,
    merchantDeleted: false,
  }

  componentDidMount() {
    authApiCall.get('/merchants/count')
      .then(response => {
        this.setState({ merchantsCount: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  private getNewPageItem(perPageLimit: number, startAt: number) {
    authApiCall.get(`/merchants?_limit=${perPageLimit}&_start=${startAt}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ merchants: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  public render(): React.ReactNode {
    const { merchants, merchantsCount } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="10">
            <PageTitle title="Merchants" subtitle="All merchants" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
          <Col lg="2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Link style={{ color: '#ffffff' }} to={RouteConstants.ADD_MERCHANT}>
              <Button theme="primary" className="mb-0 mr-0">Add New Merchant</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Current merchants</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  path={RouteConstants.MERCHANTS}
                  data={merchants}
                  columnsData={merchantTableColumn}
                  actionType={String(this.props.history.action)}
                  itemCount={merchantsCount}
                  onPaginationActionClicked={this.getNewPageItem}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}