import React from 'react';
import {
  Container, Row, Col,
  Card, CardHeader, CardBody,
  Form, FormInput, Button
} from 'shards-react';
import LoginForm from '../../components/custom/LoginForm';
import { RouteConstants } from '../../constants/RouteConstants';
import { apiCall } from '../../services/apiCallService';
import { bindActionCreators, Dispatch, } from "redux";
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation, RouteComponentProps, Redirect } from 'react-router-dom';
import { saveUserID } from '../../store/actions/userDataAction';

interface State {
  userCredentialsInvalid: boolean;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const actionCreators = {
  saveUserID
};

const connector = connect(
  mapState,
  actionCreators
);

type LoginProps = ConnectedProps<typeof connector> & RouteComponentProps;

class Login extends React.Component<LoginProps> {

  public state: State = {
    userCredentialsInvalid: false,
  }

  componentDidMount() {
    // console.warn("userdata", this.props.userData);
  }

  private loginUser(loginInfo: any) {
    console.log("----- LoggingIn User");
    apiCall.post('/auth/local', {
      identifier: loginInfo.username,
      password: loginInfo.password,
    }).then(response => {
      // console.log("----- Response", response.data);
      this.props.saveUserID(response.data);
    }).catch(error => {
      console.warn('Login error: ', error.message);
      this.setState({
        userCredentialsInvalid: true,
      }, () => {
        setTimeout(() => {
          this.setState({
            userCredentialsInvalid: false,
          })
        }, 3000);
      });
    });
  }

  public render(): React.ReactNode {
    return (
      <Container
        className="d-flex justify-content-center align-items-center vh-100"
        fluid={true}
        tag="div"
      >
        <Row>
          <Col>
            <Card style={{ width: '33.33vw' }}>
              <CardHeader>
                <div className="text-center mt-4">
                  <img
                    id="main-logo"
                    className="d-inline-block align-top text-center pa-2"
                    style={{ maxWidth: "175px" }}
                    src={require("../../images/idir_logo.png")}
                    alt="JPS Dashboard"
                  />
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                {/*Login Form Here*/}
                <LoginForm
                  submitButtonTitle="Login"
                  onButtonClick={(loginInfo: any) => this.loginUser(loginInfo)}
                  onCheckUserCredentials={this.state.userCredentialsInvalid}
                />
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

}

export default connector(Login);