import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { scanTableColumn } from '../../components/tables/tableColumn';
import { RouteConstants } from '../../constants/RouteConstants';
import { connect, ConnectedProps } from 'react-redux';

interface State {
  scans: any[];
  scanDeleted: boolean;
}

interface ComponentProps {
  scans: any | undefined;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type ScansProps = ComponentProps & ConnectedProps<typeof connector>;

class Scans extends React.Component<ScansProps, State> {

  public state: State = {
    scans: [],
    scanDeleted: false,
  }

  componentDidMount() {
    authApiCall.get(`/spectradata/findByUserID/${this.props.userData.user.id}`)
      .then(response => {
        // console.log(response.data);
        this.setState({ scans: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  public render(): React.ReactNode {
    const { scans } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="12">
            <PageTitle title="Scans" subtitle="All scans" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Scan List</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  itemCount={10}
                  path={RouteConstants.SCANS}
                  data={scans}
                  columnsData={scanTableColumn}
                  onPaginationActionClicked={() => console.log('clicked')}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default connector(Scans);