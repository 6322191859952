import React from 'react';
import { BrowserRouter as Router, RouteComponentProps } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'shards-react';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { MainTable } from '../../components/tables/MainTable';
import { spectrascanTableColumn } from '../../components/tables/tableColumn';
import { RouteConstants } from '../../constants/RouteConstants';
import { connect, ConnectedProps } from 'react-redux';
import { resetPageIndexAction } from '../../store/actions/paginationAction';

interface State {
  spectrascans: any[];
  spectrascansCount: number;
}

interface ComponentProps {
  spectrascans: any | undefined;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type SpectrascansProps = ComponentProps & RouteComponentProps & ConnectedProps<typeof connector>;

class Spectrascans extends React.Component<SpectrascansProps, State> {

  constructor(props: any) {
    super(props);
    this.getNewPageItem = this.getNewPageItem.bind(this);
  }

  public state: State = {
    spectrascans: [],
    spectrascansCount: 0,
  }

  componentDidMount() {
    // Redirect unauthenticated users
    let userType = this.props.userData.user.role.type;
    if (userType !== "superadmin") {
      this.props.history.push(RouteConstants.ADMIN);
    } else {
      authApiCall.get(`/spectradata/count`)
        .then(response => {
          this.setState({ spectrascansCount: response.data })
        })
        .catch(error => {
          console.warn(error);
        });
    }
  }

  private getNewPageItem(perPageLimit: number, startAt: number) {
    authApiCall.get(`/spectradata?_limit=${perPageLimit}&_start=${startAt}`)
      .then(response => {
        this.setState({ spectrascans: response.data })
      })
      .catch(error => {
        console.warn(error);
      })
  }

  public render(): React.ReactNode {
    const { spectrascans, spectrascansCount } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg="12">
            <PageTitle title="Spectra Scans" subtitle="All Spectra Scans" md="12" className="ml-sm-auto mr-sm-auto" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Spectra Scans List</h6>
              </CardHeader>
              <CardBody className="p-0">
                <MainTable
                  path={RouteConstants.SPECTRASCANS}
                  data={spectrascans}
                  columnsData={spectrascanTableColumn}
                  actionType={String(this.props.history.action)}
                  itemCount={spectrascansCount}
                  onPaginationActionClicked={this.getNewPageItem}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default connector(Spectrascans);