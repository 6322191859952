import { Collection } from "./Collection";
import { User } from "./User";
import { IncludeDateData } from './Include';

export interface Merchant extends IncludeDateData {
  id?: number;
  uuid: string;
  name: string;
  ssm_registration: string;
  business_category: string;
  business_size: string;
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_state: string;
  address_postcode: string;
  address_country: string;
  gps_coordinates: string;
  gps_lat?: number;
  gps_lng?: number;
  country?: string;
  user?: User;
  collections?: Collection[];
  status: string;
  user_id: number;
  last_updated: string | Date | null;
  last_synced: string | Date | null;
  is_synced: boolean;
  is_deleted: boolean;
}

export type MerchantFormData = Omit<Merchant,
'id'|'uuid'|'country'|'user'|'created_at'|
'updated_at'|'collections'|'user_id'|'last_updated'|'last_synced'|
'is_synced'|'is_deleted'>;

export const MerchantFormState = {
  name: '',
  ssm_registration: '',
  business_category: '',
  business_size: '',
  address_line1: '',
  address_line2: '',
  address_city: '',
  address_state: '',
  address_postcode: '',
  address_country: '',
  gps_coordinates: '',
  gps_lat: 0,
  gps_lng: 0,
  status: 'unapproved',
};