import { ThunkDispatch } from 'redux-thunk';
import { authApiCall } from '../../services/AuthApiCallService';
import store from '../index';

export function saveUserID(item: any) {
  return (dispatch: ThunkDispatch<any, any , any>) => {
    // Intercept and add header data during login
    authApiCall.interceptors.request.use(function (config) {
      // Do something before request is sent
      let token: any = store.getState().userData;
      let userData: any = Object.entries(token).length > 0 ? token.jwt : item.jwt;
      config.headers.Authorization = 'Bearer ' + userData;
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
    setTimeout(() => {
      dispatch(saveUser(item));
    }, 1500);
  }
};

function saveUser(item: any) {
  return { type: 'SAVE_ID', item };
}

export const logoutUser = () => ({ type: 'LOGOUT_USER' }); 