import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Progress,
  Button
} from 'shards-react';
import { RouteComponentProps } from 'react-router-dom';
import Noty from 'noty';
import UserForm from '../../components/custom/UserForm';
import PageTitle from '../../components/common/PageTitle';
import { authApiCall } from '../../services/AuthApiCallService';
import { User } from '../../models/User';
import { time } from '../../services/convertTime';
import { RouteConstants } from '../../constants/RouteConstants';
import { connect, ConnectedProps } from 'react-redux';

interface State {
  user: User;
}

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type ViewUserProps = RouteComponentProps & ConnectedProps<typeof connector>;

class ViewUser extends React.Component<ViewUserProps> {

  // private user = this.props.location.state ? this.props.location.state.data : {};

  public state: any = {
    user: null,
  }

  componentDidMount() {
    // console.log('this.state.user', this.props.location.state);
    // Get current user
    authApiCall.get(`/users/${this.props.location.state.data.id}`)
      .then(response => {
        console.log('get user Response', response);
        this.setState({ user: response.data }, () => {
          // Redirect unauthenticated users
          let userType = this.props.userData.user.role.type;
          if (userType === "authenticated") {
            this.props.history.push(RouteConstants.ADMIN);
          } else if (Object.entries(this.state.user).length <= 0) {
            this.props.history.push(RouteConstants.USERS);
          }
        });
      });
  }

  private updateUser = (user: User) => {
    authApiCall.put(`/users/${this.state.user.id}`, {
      id: this.state.user.id,
      ...user
    })
      .then(response => {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'success',
          text: 'User updated.',
          killer: true,
        }).show();

        // Update State
        this.setState({ user: response.data });

        //Replace the location state of the current route
        //delete response.data.password;
        // this.props.history.replace(this.props.location.pathname, { data: { ...response.data } });
        //2 Options:
        //1) Manually refresh the browser
        //window.location.reload();
        //2) redirect back to main list
        // this.props.history.goBack();

      }).catch(error => {
        new Noty({
          theme: 'sunset',
          layout: 'topRight',
          type: 'error',
          text: error.message,
          killer: true,
        }).show();
      });
  }

  private deleteUser = (id: any) => {
    let deletion = new Noty({
      theme: 'sunset',
      layout: 'topRight',
      text: 'Do you want to continue?',
      buttons: [
        Noty.button('YES', 'btn btn-success', () => {
          authApiCall.delete(`/users/${id}`)
            .then(response => {
              deletion.close();
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'warning',
                text: 'User Removed',
                killer: true,
              }).show();
              this.props.history.push(RouteConstants.USERS, { userDeleted: true });
            }).catch(error => {
              new Noty({
                theme: 'sunset',
                layout: 'topRight',
                type: 'error',
                text: error.message,
                killer: true,
              }).show();
              deletion.close();
            });
        }, { id: 'button1', 'data-status': 'ok' }),

        Noty.button('NO', 'btn btn-danger', () => {
          deletion.close();
        })
      ]
    });
    deletion.show();
  }

  public render(): React.ReactNode {

    const currentAccUser = this.props.userData ? this.props.userData.user.role.type : '';
    const currentUser = this.state.user ? this.state.user.role.type : '';

    console.log(this.state.user, currentAccUser, currentUser);

    return this.state.user !== null && (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={this.state.user.username}
            subtitle="User Profile" md="12"
            className="ml-sm-auto mr-sm-auto"
          />
          <strong className="title-info">{`(ID:${this.state.user.id})`}</strong>
        </Row>
        <Row>
          <Col lg="4">
            <Card small className="mb-4 pt-3">
              <CardHeader className="border-bottom text-center">
                <div className="mb-3 mx-auto">
                  <img
                    className="rounded-circle"
                    src={`https://avatars.dicebear.com/v2/initials/${this.state.user.username}.svg`}
                    alt={`${this.state.user.username}'s Avatar`}
                    width="110"
                  />
                </div>
                <h4 className="mb-0">{this.state.user.username}</h4>
                <span className="text-muted d-block mb-2">{this.state.user.email}</span>
                <span className={`badge badge-${this.state.user.confirmed ? 'success' : 'danger'}`}>
                  {this.state.user.confirmed ? 'Confirmed' : 'Not Confirmed'}
                </span>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-4">
                  <strong className="text-muted d-block mb-2">
                    Joined At
                  </strong>
                  <span>{time(this.state.user.created_at)}</span>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
          <Col lg="8" className="mb-4">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <Col lg="6">
                    <h6 className="m-0">User Detail</h6>
                  </Col>
                  <Col lg="6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!(currentAccUser === "admin" && currentUser === "admin") && (
                      <Button onClick={() => this.deleteUser(this.state.user.id)} theme="danger" className="mb-0">
                        Remove User
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {Object.entries(this.state.user).length > 0 && (
                  <UserForm userInfo={this.state.user} submitButtonTitle="Update User" onButtonClick={this.updateUser} />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    )
  }

}

export default connector(ViewUser);