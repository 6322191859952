import React from 'react';
import {
  Container, Row, Col,
  Card, CardHeader, CardBody
} from 'shards-react';
import { RouteComponentProps } from 'react-router-dom';
import { User } from '../../models';
import PageTitle from '../../components/common/PageTitle';
import UserForm from "../../components/custom/UserForm";
import { authApiCall } from '../../services/AuthApiCallService';
import { connect, ConnectedProps } from 'react-redux';
import { RouteConstants } from '../../constants/RouteConstants';
import Noty from 'noty';

const mapState = (state: any) => (
  { userData: state.userData }
);

const connector = connect(
  mapState
);

type AddUserProps = RouteComponentProps & ConnectedProps<typeof connector>;

class AddUser extends React.Component<AddUserProps> {

  componentDidMount() {
    // Redirect unauthenticated users
    let userType = this.props.userData.user.role.type;
    if (userType === "authenticated") {
      this.props.history.push(RouteConstants.ADMIN);
    }
  }

  private addNewUserClick = (user: User) => {
    authApiCall.post('/users', {
      ...user,
      confirmed: true,
    })
    .then(response => {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'success',
        text: 'New User Added',
        killer: true,
      }).show();
      this.props.history.push('/users');
    }).catch(error => {
      new Noty({
        theme: 'sunset',
        layout: 'topRight',
        type: 'error',
        text: error.message,
        killer: true,
      }).show();
    })
  }

  public render(): React.ReactNode {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Add User" subtitle="Add New User" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="12" className="mb-4">
            {/* Complete Form Example */}
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">New User</h6>
              </CardHeader>
              <UserForm isAddNewUser submitButtonTitle="Add New User" onButtonClick={this.addNewUserClick} />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

}

export default connector(AddUser);